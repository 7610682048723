import {update} from "react-querybuilder";
import {crud} from "../../../../../crudRequests";
import {v4 as uuidv4} from "uuid";
import Swal from "sweetalert2";
export default async function makeKeyNote({note, state}) {
  try {
    if (note) {
      if (note.keyNote) {
        let result = await Swal.fire({
          title:
            "Are you sure you want to unset this note as the Key Note for the patient?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
          didOpen: () => {
            // Agregar listeners al abrir el modal
            const confirmButton = Swal.getConfirmButton();
            const cancelButton = Swal.getCancelButton();

            if (confirmButton) {
              confirmButton.addEventListener("click", (event) => {
                event.stopPropagation();
              });
            }

            if (cancelButton) {
              cancelButton.addEventListener("click", (event) => {
                event.stopPropagation();
              });
            }
          },
        });
        if (result.isConfirmed) {
          await crud(state, [
            {
              db: state.db,
              collection: "patientNotes",
              parameters: [{noteId: note.noteId}, {$set: {keyNote: false}}],
              method: "updateOne",
            },
          ]);
          return;
        }
      } else {
        let result = await Swal.fire({
          title:
            "Are you sure you want to set this note as the Key Note for the patient?",
          text: "This note will be the Key Note for this patient!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
          didOpen: () => {
            // Agregar listeners al abrir el modal
            const confirmButton = Swal.getConfirmButton();
            const cancelButton = Swal.getCancelButton();

            if (confirmButton) {
              confirmButton.addEventListener("click", (event) => {
                event.stopPropagation();
              });
            }

            if (cancelButton) {
              cancelButton.addEventListener("click", (event) => {
                event.stopPropagation();
              });
            }
          },
        });
        if (result.isConfirmed) {
          await crud(state, [
            {
              db: state.db,
              collection: "patientNotes",
              parameters: [{}, {$set: {keyNote: false}}],
              method: "updateMany",
            },
          ]);
          await crud(state, [
            {
              db: state.db,
              collection: "patientNotes",
              parameters: [
                {noteId: note.noteId},
                {$set: {keyNote: true, aid: ""}},
              ],
              method: "updateOne",
            },
          ]);
          return;
        }
      }
    }
  } catch (error) {
    throw error;
  }
}
