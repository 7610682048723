import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import {Button, useToast, Flex, Text, Badge} from "@chakra-ui/react";
import {TiShoppingCart} from "react-icons/ti";
import {crud} from "../../../crudRequests";
import {BiChevronDown, BiLink} from "react-icons/bi";
import DefineApptServiceCriteria from "../../DefineApptServiceCriteria";
import deleteFiles from "./helpers/deleteFiles";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import InvRecpDF from "../../Invoice";
import {pdf} from "@react-pdf/renderer";
import SelectedProducts from "../../Products/SelectedProducts";
import {lightFormat} from "date-fns";
import {PiWarningBold} from "react-icons/pi";
import fetchProducts from "../../Products/helpers/fetchProducts";
import ExternalSales from "../../Products/ExternalSales";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerInvoice,
  insurerReceipt,
} from "./uploads";

let durations = [
  "15 min",
  "30 min",
  "45 min",
  "60 min",
  "75 min",
  "90 min",
  "105 min",
  "120 min",
];
let durationLabels = [
  "15 min",
  "30 min",
  "45 min",
  "1 hr",
  "1.25 hr",
  "1.5 hr",
  "1.75 hr",
  "2 hr",
];

function durationFormat(timeString) {
  let t = timeString.split(" ")[0];
  return Number(t) <= 45 ? timeString : `${(Number(t) / 60).toFixed(2)} hr`;
}

export default function ChangeAmount({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
  setErrorMessage,
  setSuccess,
}) {
  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col items-start space-y-4">
      <div className="flex justify-start items-center  px-2">
        <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
          Payment Criteria
        </h1>
      </div>

      <SendForm
        state={state}
        patient={patient}
        appt={appt}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
}

function SendForm({
  state,
  patient,
  appt,
  stateChanger,
  dispatch,
  socket,
  setErrorMessage,
}) {
  const [duration, setDuration] = useState(appt.duration);
  const [sending, setSending] = useState(false);
  const toast = useToast();
  const criteriaKeyRef = useRef(null);
  const [service, setService] = useState(
    () =>
      state.services.find(
        (s) => appt.serviceId === s.serviceId || appt.service === s.serviceName
      ) || state.services[0]
  );

  const [durationsIndex, setDurationsIndex] = useState(() => {
    let i = durations.findIndex((e) => e === appt.duration);
    return i !== -1 ? i : 0;
  });

  const [showList, setShowList] = useState(false);
  const [showList2, setShowList2] = useState(false);
  const [serviceCriteriaKey, setServiceCriteriaKey] = useState(
    appt.serviceCriteriaKey || `Doctors|${appt.did}`
  );

  if (serviceCriteriaKey === "") {
    setServiceCriteriaKey(appt.serviceCriteriaKey || `Doctors|${appt.did}`);
  }

  /////////////
  let criteria = useMemo(() => {
    let crt = state.serviceCriteria.filter((c) => c.type !== "Insurers");

    let additionalCriteria = [
      {type: "Insurers", list: state.insurers},
      {type: "Provider Types", list: state.providerTypes},
    ];
    let doctorCriteriaList = state?.doctors?.filter((e) => e.did === appt.did);
    if (doctorCriteriaList.length === 0 || !doctorCriteriaList) {
      if (state?.doctor) {
        doctorCriteriaList = [state?.doctor];
      }
    }
    additionalCriteria.push({
      type: "Clinicians",
      list: doctorCriteriaList,
    });

    return crt.concat(additionalCriteria);
  }, [state.serviceCriteria, state.insurers, state.doctors]);

  let initialCriteria = useMemo(() => {
    let initialCriteriaIdValues = criteriaFormatIdByKey(
      appt.serviceCriteriaKey,
      criteria
    );
    criteriaKeyRef.current = initialCriteriaIdValues;
    let initialCriteriaValues = appt.serviceCriteriaKey
      ? Object.fromEntries(
          criteriaFormatByKey(appt.serviceCriteriaKey, criteria)
        )
      : {};

    let initialSelectedCriteria = appt.serviceCriteriaKey
      ? criteria.filter((e) => {
          return appt.serviceCriteriaKey.match(new RegExp(e.type + "\\W"));
        })
      : [];

    return {
      initialCriteriaIdValues,
      initialCriteriaValues,
      initialSelectedCriteria,
    };
  }, [criteria]);

  ///////////////
  const [tax, setTax] = useState(() => {
    if (service?.taxable) {
      let typeId = criteriaKeyRef.current?.["Provider Types"];

      if (typeId) {
        let providerType = state.providerTypes.find((t) => t.typeId === typeId);

        return providerType?.tax || 0;
      }
      return appt.tax || 0;
    }

    return 0;
  });

  const [amount, setAmount] = useState(() => {
    if (appt.serviceAmount !== undefined && !isNaN(appt.serviceAmount))
      return appt.serviceAmount;
    return service?.defaultCost || 100;
    /*
    let costsByCriteria = service?.defaultCost || 100;
    if (serviceCriteriaKey&&service&&!service?.isfixedCost)
      costsByCriteria =
        service.costsByCriteria?.[appt.serviceCriteriaKey] ?? costsByCriteria;

    return Number.parseFloat(
      (Number.parseFloat(appt.duration.split(" ")[0]) / 60) *
        Number.parseFloat(costsByCriteria)
    ).toFixed(2);*/
  });

  const [tppAmount, setTppAmount] = useState(
    Number.parseFloat(appt.tppAmount ?? 0)
  );

  const [subTotal, setSubTotal] = useState(
    (amount / (1 + tax / 100)).toFixed(2)
  );

  const init = useRef(true);

  const list = useRef(showList);
  const list2 = useRef(showList2);

  list.current = showList;
  list2.current = showList2;

  const patientTppInfo = useMemo(() => {
    if (Array.isArray(patient?.tpp)) {
      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      return patient?.tpp.find((ins) => ins.insurerId === tpp);
    }
    return null;
  }, [serviceCriteriaKey, patient]);

  const [products, setProducts] = useState([]);
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [productSalesInformation, setProductSalesInformation] = useState({
    products: appt.products || [],
    tax: appt.productTax || 0,
    amount: appt.productAmount || 0,
  });
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productSaleClinicianId, setProductSaleClinicianId] = useState(null);
  useEffect(() => {
    fetchProducts(state, setLoadingProducts, setProducts, toast);
  }, []);

  useEffect(() => {
    if (!init.current) {
      let tax = 0;
      if (service?.taxable === true) {
        let typeId = criteriaKeyRef.current?.["Provider Types"];

        if (typeId) {
          let providerType = state.providerTypes.find(
            (t) => t.typeId === typeId
          );
          tax = providerType?.tax || 0;
        }
      }

      let costsByCriteria = service?.defaultCost || 100;
      if (serviceCriteriaKey && !service?.isfixedCost)
        costsByCriteria =
          service?.costsByCriteria?.[serviceCriteriaKey] ?? costsByCriteria;

      let hours = Number.parseFloat(duration.split(" ")[0]) / 60;
      let amt = Number.parseFloat(
        (service?.isfixedCost ? 1 : hours) * Number.parseFloat(costsByCriteria)
      ).toFixed(2);

      amt = (amt * (1 + tax / 100)).toFixed(2);
      setSubTotal((amt / (1 + tax / 100)).toFixed(2));
      setAmount(amt);
      setTax(tax);
      let tpp = criteriaKeyRef.current?.["Insurers"];
      let tppAmount = tpp
        ? amt - appt.patientAmount > 0
          ? amt - appt.patientAmount
          : 0
        : 0;

      let patientTppInfo = null;
      if (Array.isArray(patient?.tpp) && tpp) {
        patientTppInfo = patient?.tpp.find((ins) => ins.insurerId === tpp);
      }

      if (
        patientTppInfo &&
        !isNaN(patientTppInfo.sessionDeductionAmount) &&
        parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
      ) {
        tppAmount = parseFloat(patientTppInfo.sessionDeductionAmount);
      }
      setTppAmount(tppAmount);
    } else init.current = false;
  }, [durationsIndex, service, serviceCriteriaKey]);

  let total =
    parseFloat(amount) + parseFloat(productSalesInformation?.amount || 0);
  let patientAmount = appt.paidByMembership
    ? Math.max(parseFloat(productSalesInformation?.amount || 0) - tppAmount, 0)
    : total - tppAmount;

  async function changeCriteria() {
    let tpp = criteriaKeyRef.current?.["Insurers"];
    if (
      patientTppInfo &&
      !isNaN(patientTppInfo.noOfInsuredSessions) &&
      parseFloat(patientTppInfo.noOfInsuredSessions) <= 0
    ) {
      toast({
        title: "Update error.",
        description: "There are no Insured Sessions for the selected insurer",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (service && patient) {
      setSending(true);
      setErrorMessage(false);
      let tppA = parseFloat(tppAmount);
      let ptAmount = parseFloat(patientAmount);

      let amountData = {
        amount:
          parseFloat(amount) + parseFloat(productSalesInformation?.amount || 0),
        serviceTax: parseFloat(tax),
        serviceAmount: parseFloat(amount),
        products: productSalesInformation?.products || [],
        productTax: parseFloat(productSalesInformation?.tax || 0),
        productAmount: parseFloat(productSalesInformation?.amount || 0),
        tppAmount: tppA,
        patientAmount: ptAmount,
      };

      let updateBody = {
        ...appt,
        duration,
        service: service.serviceName,
        serviceId: service.serviceId,
        serviceCriteriaKey,
        tpp,
        ...amountData,
      };

      if (ptAmount - updateBody.amountPaidByPatient > 0) {
        updateBody.patientPaymentStatus = "pending";
      }

      if (tppA - updateBody.amountPaidByTpp > 0) {
        updateBody.claim = updateBody.received = false;
      }

      let {_id, ...data} = updateBody;

      crud(state, [
        {
          db: state.db,
          collection: appt?.nonSessionService
            ? "nonSessionServices"
            : "appointments",
          parameters: [{aid: updateBody.aid}, {$set: data}],
          method: "updateOne",
        },
        {
          db: state.db,
          collection: "billing",
          parameters: [
            {aid: updateBody.aid},
            {
              $set: {
                serviceType: service.serviceName,
                serviceId: service.serviceId,
                time: duration,
                patientPaymentStatus: updateBody.patientPaymentStatus,
                tpp,
                claim: updateBody.claim,
                received: updateBody.received,
                ...amountData,
                productSaleClinicianId:
                  productSalesInformation.productSaleClinicianId,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
        {
          db: state.db,
          collection: "doctors",
          parameters: [{did: appt.did}],
          method: "findOne",
        },
        {
          db: state.db,
          collection: "patients",
          parameters: [{pid: appt?.pid}],
          method: "findOne",
        },
        {
          db: state.db,
          collection: "insurers",
          parameters: [{iid: tpp || ""}],
          method: "findOne",
        },
        {
          db: state.db,
          collection: "doctors",
          parameters: [{did: appt.supervisorId || ""}],
          method: "findOne",
        },
      ])
        .then(async (res) => {
          try {
            if (
              appt.patientAmount !== res.data[1].patientAmount ||
              appt.tppAmount !== res.data[1].tppAmount ||
              res.data[1].tpp !== appt.tpp
            ) {
              let invData = {
                state: state,
                transaction: res.data[1],
                doctor: res.data[2],
                patient: res.data[3],
                tpp: res.data[4],
                supervisor: res.data[5],
                nonSessionService: res.data[1]?.nonSessionService,
              };

              await Promise.all([
                patientInvoice(invData),
                fullInvoice(invData),
              ]);
              if (res.data[4]) await insurerInvoice(invData);
            }

            dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
            socket?.emit?.("update_appt", updateBody, {});
            stateChanger(false);
            setSending(false);
          } catch (error) {
            console.log(error);
          }
        })
        .catch(function (error) {
          console.log(error);
          setSending(false);
          setErrorMessage(error.response.data);
        });
    }
  }

  return (
    <>
      {showSaleModal && (
        <ExternalSales
          {...{
            state,
            products,
            closeSalesModal: () => setShowSaleModal(false),
            productSalesInformation,
            callback: (productData) => {
              setProductSaleClinicianId(productData.productSaleClinicianId);
              setProductSalesInformation(productData);
            },
            selectedClinicianId: productSaleClinicianId || appt.did || null,
          }}
        />
      )}
      <form
        className="w-full flex justify-start items-center"
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <div className="bg-[#E7E6FF] rounded-2xl border flex flex-col w-full justify-between items-center space-y-7 p-7 pt-10">
          <div className="w-full relative flex justify-between   space-x-3">
            <div className="relative  flex flex-col  justify-between">
              <div
                className="bg-white z-10 relative cursor-pointer rounded-xl  flex justify-between items-center p-2 pl-4 pr-10"
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowList2((s) => false);
                  if (state.services.length > 0) setShowList((s) => !s);
                }}
              >
                {state.services.length > 0 && (
                  <>
                    <p className="relative  w-[12rem] text-[#8F8CFF]  font-semibold  h-8 flex items-center text-[18px]">
                      <span className="absolute text-[#A2A2A2] text-xs bottom-[2.7rem] -left-1">
                        Services
                      </span>
                      <input
                        type="text"
                        className="border-none focus:ring-0 w-full p-0 focus:outline-none"
                        contentEditable="false"
                        value={service?.serviceName || ""}
                        readOnly={true}
                      />
                    </p>
                    <span className="absolute cursor-pointer right-3 text-3xl stroke-2 text-[#8F8CFF]">
                      <BiChevronDown />
                    </span>
                    {showList && (
                      <div className="shadow-lg absolute bg-white rounded-xl p-1  w-full left-0 right-10  top-[3.5rem] max-h-[12.5rem]">
                        <ul className="overflow-y-scroll w-full sbar3 max-h-[12rem] divide-y-[1px] p-1 space-y-1">
                          <div className="flex flex-col flex-wrap">
                            {state.services.map((e, i) => (
                              <li
                                className="text=[#8F8CFF]  flex-wrap flex text-[#8F8CFF] font-semibold  pl-1 text-[1rem] hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
                                onClick={(ev) => {
                                  // ev.stopPropagation();
                                  setService(e);
                                }}
                                key={i}
                              >
                                {e.serviceName}
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>
              <Details
                details={[
                  {Category: service?.category || "-"},
                  {"Default Cost/hrs": service?.defaultCost || "-"},
                  {"Default Duration": service?.defaultDuration || "-"},
                ]}
              />
              {parseInt(patientTppInfo?.noOfInsuredSessions ?? -1) === 0 &&
                patientTppInfo?.insurerId !== appt.tpp && (
                  <div className="">
                    <span className="ml-2 text-yellow-500 text-xs space-x-1 flex items-center">
                      <PiWarningBold />{" "}
                      <span>
                        All sessions insured by this TPP have been used up!{" "}
                      </span>
                    </span>
                  </div>
                )}
              <div className=" font-bold text-lg flex justify-center items-center p-2 w-full mt-1  whitespace-nowrap overflow-hidden  text-off  bg-white border border-gray-200 rounded-2xl ">
                <p>{`SERVICE COST: $${amount}`}</p>
              </div>
            </div>

            <div>
              <div
                className="bg-white relative z-10 cursor-pointer rounded-xl   flex justify-between items-center p-2 pl-4 pr-12"
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowList2((s) => !s);
                  setShowList((s) => false);
                }}
              >
                {
                  <>
                    <p className="relative w-[4rem] px-2 pr-4 whitespace-nowrap text-[#8F8CFF] font-semibold text-center  h-8  flex items-center text-[18px]">
                      <span className="absolute text-[#A2A2A2] text-xs bottom-[2.7rem] -left-2">
                        Duration
                      </span>
                      {duration || ""}
                    </p>
                    <span className="absolute cursor-pointer right-2 text-3xl stroke-2 text-[#8F8CFF]">
                      <BiChevronDown />
                    </span>
                    {showList2 && (
                      <div className="shadow-lg absolute bg-white rounded-xl p-1  w-full left-0 right-10  top-[3.5rem] ">
                        <ul className="overflow-y-scroll w-full sbar3 max-h-[12rem] divide-y-[1px] pr-2 space-y-1">
                          {durations.map((e, i) => (
                            <li
                              className="text=[#8F8CFF] text-center rounded-lg text-[#8F8CFF] font-semibold  pl-2 text-[1rem] hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
                              onClick={() => {
                                setDurationsIndex(i);
                                setDuration(e);
                              }}
                              key={i}
                            >
                              {durationFormat(e)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                }
              </div>
              <label className="block  mt-1">
                <span className="text-[12px] text-[#A2A2A2] text-xs font-medium ml-2">
                  Service Subtotal
                </span>
                <input
                  type="number"
                  className=" block w-[8rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={subTotal}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setAmount(0);
                      setTppAmount(0);
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;

                    if (!isNaN(value) && !value.match(/-/g)) {
                      if (value === "") value = 0;
                      setAmount(
                        (
                          parseFloat(value) *
                          (1 + parseFloat(tax) / 100)
                        ).toFixed(2)
                      );
                      setSubTotal(value);
                      if (tppAmount > parseFloat(value))
                        setTppAmount(parseFloat(value));
                    }
                  }}
                ></input>
              </label>
              <label className="block  mt-1">
                <span className="text-[12px] text-[#A2A2A2] text-xs font-medium ml-2">
                  Service Tax Rate (%)
                </span>
                <input
                  type="number"
                  className=" block w-[8rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={tax}
                  onChange={(e) => {
                    let value = e.target.value;

                    if (!isNaN(value) && !value.match(/-/g)) {
                      if (value === "") value = 0;
                      setAmount(
                        (
                          parseFloat(subTotal) *
                          (1 + parseFloat(value) / 100)
                        ).toFixed(2)
                      );
                      setTax(value);
                    }
                  }}
                ></input>
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col items-end space-x-3">
            <div className="flex flex-col justify-start flex-1 overflow-hidden w-full space-y-1 -mt-3">
              <span className="text-[12px] text-[#A2A2A2] text-xs font-medium ml-2">
                Criteria for this service
              </span>
              <div className="border border-white  w-full rounded-xl overflow-hidden flex-1 p-1">
                <ul className="sbar2 space-y-1 h-full overflow-y-scroll  sbar2 w-full p-2 pt-3">
                  {serviceCriteriaKey?.length === 0 ? (
                    Array(1)
                      .fill(0)
                      .map((e, i) => (
                        <li
                          key={i}
                          className="rounded-md w-full bg-gray-100/80 h-7"
                        ></li>
                      ))
                  ) : (
                    <li className="relative rounded-md w-full  flex  text-xs font-medium space-x-2">
                      {criteriaFormatByKey(serviceCriteriaKey, criteria).map(
                        ([type, value], ind) => (
                          <div
                            className="flex-1 p-1 flex items-center flex-col rounded-md bg-white border border-gray-200"
                            key={ind}
                          >
                            <p className=" text-[#5754FF]">{type}</p>
                            <p className="text-off">{value}</p>
                          </div>
                        )
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <DefineApptServiceCriteria
              {...{
                service,
                criteria,
                criteriaKeyRef,
                creating: sending,
                setServiceCriteriaKey,
                initialCriteria,
              }}
            />
            <div className="w-full flex justify-between space-y-3 items-center px-3">
              <label className="block  mt-1">
                <span className="text-[12px] text-gray-800  text-xs font-medium ml-2">
                  TPP Amount
                </span>
                <input
                  type="number"
                  readOnly={!serviceCriteriaKey.match(/Insurers\|/)}
                  className=" block w-[8rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={tppAmount}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setTppAmount(0);
                    }
                  }}
                  onChange={(e) => {
                    if (parseFloat(e.target.value || 0) <= amount) {
                      setTppAmount(e.target.value);
                    }
                  }}
                ></input>
              </label>
              <label className="block  mt-1">
                <span className="text-[12px] text-gray-800  text-xs font-medium ml-2">
                  Patient Amount
                </span>
                <input
                  type="number"
                  readOnly={true}
                  className=" block w-[8rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl  focus:outline-none"
                  value={patientAmount}
                ></input>
              </label>
            </div>
          </div>
          <SelectedProducts
            {...{
              productSalesInformation,
              setProductSalesInformation,
            }}
          />
          <div className="flex w-full">
            <Flex
              align={"center"}
              gap={1}
              fontSize="sm"
              bg="white"
              rounded={"lg"}
              p="3"
            >
              <Text fontSize={"2xl"} fontWeight={700}>{`TOTAL:`}</Text>
              <Badge
                bg="#000"
                color="white"
                fontSize={"xl"}
                p="3"
                rounded={"md"}
              >{`$${total.toFixed(2)}`}</Badge>
            </Flex>
          </div>
          <div className="flex w-full">
            <Button
              leftIcon={<TiShoppingCart />}
              flex={1}
              form="buyerInfo"
              w="40"
              colorScheme="blue"
              mr={3}
              cursor={sending ? "not-allowed" : "pointer"}
              onClick={async () => {
                if (!sending) setShowSaleModal(true);
              }}
            >
              {productSalesInformation?.products.length > 0
                ? "Edit Products"
                : "Add Products"}
            </Button>
            <button
              type="submit"
              className="w-full flex-1 cursor-pointer text-white py-2 bg-med/80 hover:bg-med rounded-lg font-medium flex justify-center items-center"
              onClick={() => {
                changeCriteria();
              }}
              disabled={amount === "" || sending}
            >
              Update Criteria
              {sending && (
                <span className="ml-1">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 stroke-[4px]"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

function criteriaFormatByKey(key, criteria) {
  if (key) {
    let pairs = key
      .split("||")
      .map((e) => {
        let pair = e.split("|");
        let type = pair[0];
        let crt = criteria.find((e) => e.type === type);
        if (crt) {
          switch (type) {
            case "Clinicians": {
              let name = crt.list?.find((d) => d.did === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }

            case "Insurers": {
              let name = crt.list?.find((d) => d.iid === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }

            case "Provider Types": {
              let name = crt.list?.find((d) => d.typeId === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }
            default:
              return pair;
          }
        }
        return null;
      })
      .filter((e) => e);

    return pairs;
  }
  return [];
}
function criteriaFormatIdByKey(key, criteria) {
  if (key) {
    return key.split("||").reduce((acc, e) => {
      let pair = e.split("|");
      acc[pair[0]] = pair[1];
      return acc;
    }, {});
  }
  return {};
}

function Details({details, width}) {
  const boxRef = useRef();

  return (
    <div
      ref={boxRef}
      className="anim_height p-2 block  mt-1 shadow-inner whitespace-nowrap overflow-hidden  text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl "
      style={{width: width || "100%"}}
    >
      <ul className="flex-1 overflow-scroll h-full sbar2 overflow-x-hidden  text-off font-medium flex flex-col">
        {details.map((e, i) => {
          let [key, value] = Object.entries(e)[0];
          return (
            <li className="text-xs" key={i}>
              <span className="text-[#9F9DFA] font-medium">{`${key}: `}</span>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
