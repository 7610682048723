import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Flex,
} from "@chakra-ui/react";
import React, {useState, useEffect, useRef} from "react";
import {clinicAidClaim, crud, getClinicAidCodes} from "../../../crudRequests";
import {useToast} from "@chakra-ui/react";
import CodesTable from "./CodesTable";
import CodesList from "./CodesList";
import {PatientInfo} from "./PatientInfo";
import {InvoiceTable} from "./InvoiceTable";
import {format} from "date-fns";
import {generateClinicAidClaimPdf} from "../payment/uploads";

export default function AidClaimModal({
  appt,
  action,
  state,
  dispatch,
  patient,
  clinician,
}) {
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [sendingAidClaim, setSendingAidClaim] = useState(false);
  const [loading, setLoading] = useState(true);
  const [diagnosticCodes, setDiagnosticCodes] = useState([]);
  const [serviceCodes, setServiceCodes] = useState(() => {
    let service = state.services?.find((s) => s.serviceId === appt.serviceId);
    let serviceCodes = (service?.serviceCodes || []).map((c) => ({
      code: c,
      description: service.description || "",
      amount: service.defaultCost,
    }));
    return serviceCodes;
  });
  const [cList, setCList] = useState([]);
  const [calculations, setCalculations] = useState({
    serviceCode: null,
    diagnosticCode: null,
    amount: null,
    unit: null,
    percentage: null,
    total: null,
    overrideFee: null,
  });
  const [claimDate, setClaimDate] = useState(null);
  const [refClinicianNumber, setRefClinicianNumber] = useState(null);
  const total = useRef(0);

  let [selectedIndx, setSelectedIndx] = useState(-1);
  const [codesType, setCodesType] = useState("service");

  const getCodes = async (type, search_string = "", limit = 300) => {
    setLoading(true);
    try {
      if (!type) {
        const dc = await getClinicAidCodes(
          state,
          "diagnostic",
          search_string,
          limit
        );
        const sc = await getClinicAidCodes(
          state,
          "service",
          search_string,
          limit
        );

        if (dc.data?.result === "success") {
          setDiagnosticCodes(dc.data?.data);
        }
        if (dc.data?.result === "success") {
          setServiceCodes(sc.data?.data);
        }
      } else {
        const codes = await getClinicAidCodes(
          state,
          type,
          search_string,
          limit
        );
        if (codes.data?.result === "success") {
          if (type === "diagnostic") {
            setDiagnosticCodes(codes.data?.data);
          } else {
            setServiceCodes(codes.data?.data);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "ClinicAid Claim error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const getTransactionDate = async (aid) => {
    try {
      const res = await crud(state, [
        {
          db: state.db || "blh",
          collection: "billing",
          parameters: [{aid: aid}],
          method: "findOne",
        },
      ]);

      const transactionData = res.data && res.data[0];
      const serviceDate = transactionData
        ? new Date(transactionData.serviceDate)
        : null;

      setClaimDate(
        serviceDate
          ? format(serviceDate, "yyyy-MM-dd")
          : format(new Date().getTime(), "yyyy-MM-dd")
      );
    } catch (error) {
      console.error("Error fetching transaction date:", error);
    }
  };

  const getReferralClinician = async (refId) => {
    let referralId = refId;
    if (!referralId) {
      try {
        const res = await crud(state, [
          {
            db: state.db || "blh",
            collection: "patients",
            parameters: [
              {
                pid: patient.pid,
              },
            ],
            method: "findOne",
          },
        ]);
        const patientData = res.data[0];
        referralId = patientData?.refClinicianId;
      } catch {}
    }
    try {
      const res = await crud(state, [
        {
          db: state.db || "blh",
          collection: "refClinicians",
          parameters: [{refId: referralId}],
          method: "findOne",
        },
      ]);
      const clinicianData = res.data && res.data[0];
      setRefClinicianNumber(clinicianData?.billingNumber || null);
    } catch (error) {
      console.error("Error fetching clinician:", error);
    }
  };

  const handleClick = async () => {
    if (!sendingAidClaim && cList.length > 0) {
      setSendingAidClaim(true);
      const generatingToast = toast({
        title: "Generating Clinic Aid Claim",
        description: "Please wait a few seconds.",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      try {
        let res = await clinicAidClaim(
          state,
          appt?.aid,
          cList.map((s) => ({
            sCode: s?.serviceCode,
            dCode: s?.diagnosticCode,
            sDescription: s?.serviceDescription,
            dDescription: s?.diagnosticDescription,
            amount: parseFloat(s?.amount),
            unit: parseFloat(s?.unit ?? 0),
            percentage: parseFloat(s?.percentage ?? 0),
            total: parseFloat(s?.total),
            overrideFee: parseFloat(s?.overrideFee ?? 0),
          })),
          claimDate,
          refClinicianNumber,
          parseFloat(total.current).toFixed(2)
        );
        res = res.data;

        toast.close(generatingToast);
        if (res.aidClaimResponse.result === "error") {
          let s = "Error in generating ClinicAid claim. Please try again.";
          try {
            s =
              Object.entries(res.aidClaimResponse?.errors)
                .map(
                  ([key, value], i) =>
                    `${key}: (${Object.entries(value?.field_errors)
                      .map(([k, v]) => `${k}: ${v.join()}`)
                      .join(", ")}\n ${
                      value?.standard_errors.length > 0
                        ? "- " + value?.standard_errors.join(", ")
                        : ""
                    } )`
                )
                .join("\n") + ".";
          } catch {}
          toast({
            title: "ClinicAid Claim error",
            description: s,
            status: "error",
            isClosable: true,
          });
        } else {
          toast({
            title:
              "Your claim is succesfully created in ClinicAid! Please log in to ClinicAid to see the claim status.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch({
            type: "UPDATE_APPOINTMENT",
            payload: {...appt, ...res.apptUpdateData},
          });
          onClose();
          action();
          const invoiceData = res.invoiceClaimData;
          await generateClinicAidClaimPdf({invoiceData, state});
        }
        setSendingAidClaim(false);
      } catch (e) {
        console.log(e.message);
        setSendingAidClaim(false);
        toast.close(generatingToast);
        toast({
          title: "ClinicAid Claim error",
          description: e.message,
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Incomplete Data",
        description: "Please add data.",
        status: "warning",
        duration: 2000,
      });
    }
  };

  const updateCalculations = (field, value) => {
    setCalculations((prevCalculations) => {
      if (prevCalculations[field] === value) {
        return prevCalculations;
      }

      const updatedCalculations = {
        ...prevCalculations,
        [field]: value,
      };

      const baseTotal =
        updatedCalculations.amount * (updatedCalculations.unit || 1);
      const calculatedTotal = updatedCalculations.overrideFee
        ? updatedCalculations.overrideFee
        : baseTotal +
          baseTotal *
            (updatedCalculations.percentage
              ? updatedCalculations.percentage / 100
              : 0);

      return {
        ...updatedCalculations,
        total: Math.round(parseFloat(calculatedTotal) * 100) / 100,
      };
    });
  };

  const handleSelect = (code, amount, description) => {
    if (codesType === "service") {
      setCalculations((prevCalculations) => {
        return {
          ...prevCalculations,
          serviceCode: code,
          serviceDescription: description,
        };
      });
      updateCalculations("amount", amount);
      setServiceCodes(
        serviceCodes.filter(
          (c) =>
            c.code.match(new RegExp(code, "i")) ||
            c.description?.match(new RegExp(code, "i"))
        )
      );
    } else {
      setCalculations((prevCalculations) => {
        return {
          ...prevCalculations,
          diagnosticCode: code,
          diagnosticDescription: description,
        };
      });
      setDiagnosticCodes(
        diagnosticCodes.filter(
          (c) =>
            c.code.match(new RegExp(code, "i")) ||
            c.description?.match(new RegExp(code, "i"))
        )
      );
    }
  };

  useEffect(() => {
    getCodes(null);
    getTransactionDate(appt.aid);
    getReferralClinician(patient.refClinicianId || null);
    return () => action();
  }, []);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        action();
      }}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent sx={{maxW: "70vw"}}>
        <ModalHeader>File ClinicAid Claim</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <PatientInfo
              patient={patient}
              clinician={clinician}
              diagnosticCodesNotes={appt.diagnosticCodesNotes ?? ""}
              claimDate={claimDate}
              setClaimDate={setClaimDate}
              state={state}
              refClinicianNumber={refClinicianNumber}
            />
            <CodesList
              {...{
                cList,
                setCList,
                setCodesType,
                setSelectedIndx,
                selectedIndx,
                getCodes,
                calculations,
                setCalculations,
                total,
                updateCalculations,
              }}
            />
            <Flex height={"150px"} alignItems="center" direction={"column"}>
              <CodesTable
                codesType={codesType}
                onSelect={handleSelect}
                codes={codesType === "service" ? serviceCodes : diagnosticCodes}
                loading={loading}
              />
            </Flex>
            <Flex
              height={"200px"}
              alignItems="center"
              direction={"column"}
              mt="0.5rem"
            >
              <InvoiceTable cList={cList} setCList={setCList} total={total} />
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            cursor={
              !sendingAidClaim && cList.length > 0 ? "pointer" : "not-allowed"
            }
            onClick={handleClick}
          >
            Create Invoice
          </Button>
          <Button
            onClick={() => {
              onClose();
              action();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
