import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  SkeletonText,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Tooltip,
  Icon,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
  HStack,
} from "@chakra-ui/react";
import {FaKey} from "react-icons/fa6";
import {VscFileSymlinkFile} from "react-icons/vsc";
import React, {useState, useEffect, useRef, useLayoutEffect} from "react";

import fetchNotes from "./helpers/fetchNotes";
export default function NotesOnHover({
  state,
  appointment,
  setPopoverContentWith,
}) {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes(appointment, state, (n) => {
      setNotes(n);
      setLoading(false);
    });
  }, []);
  useLayoutEffect(() => {
    if (notes.length === 0 && !loading) setPopoverContentWith("10rem");
  }, [notes, loading]);

  return (
    <VStack
      p="2"
      w="full"
      justifyContent={"center"}
      divider={<StackDivider borderColor="gray.200" />}
    >
      {loading ? (
        <Loader />
      ) : notes.length > 0 ? (
        notes.map((n, i) => <Note key={i} note={n} />)
      ) : (
        <Box fontSize={"sm"} color={"gray.600"} w="full" fontWeight={"600"}>
          No note
        </Box>
      )}
    </VStack>
  );
}

function Note({note}) {
  return (
    <Card shadow={"none"} w="full" bg="transparent" p="0">
      <CardBody p="2">
        <VStack gap={1} w="full" justifyContent={"flex-start"}>
          <HStack
            gap={0}
            w="full"
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Heading
              color={"gray.700"}
              text
              size="xs"
              textAlign={"start"}
              whiteSpace={"pre-wrap"}
              flex={1}
            >
              {note.title}
            </Heading>
            {note.keyNote && (
              <FaKey className="text-green-700 mt-1" fontSize="10px" />
            )}
          </HStack>

          <Text
            color={"gray.500"}
            lineHeight={"16px"}
            w="full"
            fontSize="sm"
            textAlign={"start"}
            whiteSpace={"pre-wrap"}
          >
            {note.description}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
function Loader() {
  return (
    <Box padding="2" w="full">
      <SkeletonText
        noOfLines={1}
        bg="gray.300"
        spacing="1"
        skeletonHeight="2"
        w="50px"
      />
      <SkeletonText mt="3" noOfLines={4} spacing="1" skeletonHeight="2" />
    </Box>
  );
}
