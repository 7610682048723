import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  FormControl,
  InputLeftElement,
  Grid,
} from "@chakra-ui/react";
import {PhoneIcon, AtSignIcon} from "@chakra-ui/icons";

import {useMemo} from "react";
import {SearchableSelect} from "../../SearchableSelect";
import {alphabeticalSort} from "../../Tasks/helpers/alphabeticalSort";

export default function BuyerInformation({fields, setFields, state}) {
  const sortedDoctorOptions = useMemo(() => {
    return alphabeticalSort(state.doctors ?? [state.doctor], "lastName").map(
      (option) => ({
        value: option.did,
        label: `${option.lastName}, ${option.firstName}`,
      })
    );
  }, [state.doctor?.did, state.doctors?.length]);
  return (
    <Box w="full">
      <Grid templateColumns={"repeat(2, 1fr)"} gap={6}>
        {/* Row 1 */}
        <FormControl id="firstName" isRequired>
          <FormLabel fontSize={"sm"} color={"gray.500"}>
            First Name
          </FormLabel>
          <Input
            readOnly={true}
            size="sm"
            variant="outline"
            rounded={"md"}
            type="text"
            value={fields.firstName}
            onChange={(e) => {
              setFields((prev) => {
                return {
                  ...prev,
                  firstName: e.target.value,
                };
              });
            }}
          />
        </FormControl>
        <FormControl id="lastName" isRequired>
          <FormLabel fontSize={"sm"} color={"gray.500"}>
            Last Name
          </FormLabel>
          <Input
            readOnly={true}
            size="sm"
            variant="outline"
            rounded={"md"}
            type="text"
            value={fields.lastName}
            onChange={(e) => {
              setFields((prev) => {
                return {
                  ...prev,
                  lastName: e.target.value,
                };
              });
            }}
          />
        </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel fontSize={"sm"} color={"gray.500"}>
            Email
          </FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon>
              <AtSignIcon color="gray.300" />
            </InputLeftAddon>

            <Input
              readOnly={true}
              variant="outline"
              rounded={"md"}
              type="text"
              value={fields.pEmail}
              onChange={(e) => {
                setFields((prev) => {
                  return {
                    ...prev,
                    pEmail: e.target.value,
                  };
                });
              }}
            />
          </InputGroup>
        </FormControl>
        <FormControl id="phone">
          <FormLabel fontSize={"sm"} color={"gray.500"}>
            Phone
          </FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon>
              <PhoneIcon color="gray.300" />
            </InputLeftAddon>

            <Input
              readOnly={true}
              variant="outline"
              rounded={"md"}
              type="text"
              value={fields.phone}
              onChange={(e) => {
                setFields((prev) => {
                  return {
                    ...prev,
                    phone: e.target.value,
                  };
                });
              }}
            />
          </InputGroup>
        </FormControl>
      </Grid>
      <Box w="50%" zIndex={1000}>
        <SearchableSelect
          options={sortedDoctorOptions}
          label="Select clinician"
          placeholder="-"
          value={fields.productSaleclinicianId}
          onChange={(option) =>
            setFields((prev) => ({
              ...prev,
              productSaleClinicianId: option.value,
            }))
          }
          isRequired={false}
        />
      </Box>
      <FormControl id="note" mt={"6"}>
        <FormLabel fontSize={"sm"} color={"gray.500"}>
          Notes
        </FormLabel>

        <InputGroup>
          <Textarea
            placeholder="write a note..."
            size="sm"
            resize={"vertical"}
            rounded={"md"}
            value={fields.note}
            onChange={(e) => {
              let value = e.target.value;

              setFields((prev) => {
                return {
                  ...prev,
                  note: value,
                };
              });
            }}
          />
        </InputGroup>
      </FormControl>
    </Box>
  );
}
