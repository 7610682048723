import {useState, useEffect} from "react";
import PatientPayment from "./PatientPayment";
import TppPayment from "./TppPayment";
import ChangeAmountModal from "./ChangeAmountModal";
import {getFileData} from "./helpers/getFileData";
import PreviewFile from "./helpers/PreviewFile";
import {Button, useToast} from "@chakra-ui/react";
import {viewFiles} from "./helpers/viewFiles";
import {lightFormat} from "date-fns";
import {initialInvoices} from "./uploads";
import {ClinicAidSection} from "./ClinicAidSection";
import {
  Box,
  Flex,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Text,
  Link,
} from "@chakra-ui/react";
export default function PaymentInformation({
  appointment,
  state,
  dispatch,
  socket,
  sendingAidClaim,
  setSendingAidClaim,
}) {
  let status = appointment ? appointmentStatus(appointment) : null;

  const [open, setOpen] = useState(
    status && status !== "pConfirmed" && status !== "unconfirmed"
  );
  const [prevAppt, setPrevAppt] = useState(appointment);
  if (prevAppt !== appointment) {
    setPrevAppt(appointment);
    setOpen(status && status !== "pConfirmed" && status !== "unconfirmed");
  }

  const [appt, setAppt] = useState(appointment);
  const [amountModal, setAmountModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [fullInvoiceFile, setFullInvoiceFile] = useState([]);
  const [fullReceiptFile, setFullReceiptFile] = useState([]);

  const toast = useToast();
  const [fetchingData, setFetchingData] = useState(false);
  let invoicesPath = `${appointment?.pid || ""}/Invoices/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}`;
  let receiptsPath = `${appointment?.pid || ""}/Receipts/${
    appointment?.nonSessionService ? "Non-Session-Services" : "Appointments"
  }/${appointment?.aid ? fileNameFormat(appointment) : ""}`;

  const [creatingInv, setCreatingInv] = useState(false);
  const [invOrRecExist, setInvOrRecExist] = useState({
    ptInv: false,
    fullInv: false,
    tppInv: false,
    check: false,
  });
  let {ptInv, fullInv, tppInv} = invOrRecExist;

  function fileNameFormat(appt) {
    return (
      lightFormat(new Date(appt.ISOdate), "dd-MM-yy HH-mm aaa") +
      ` (${appt.aid})/`
    );
  }

  useEffect(() => {
    if (appointment) {
      setAppt(appointment);
      setFullInvoiceFile([]);
      setFullReceiptFile([]);
      viewFiles(state, setFullInvoiceFile, () => {}, invoicesPath, toast);
      viewFiles(state, setFullReceiptFile, () => {}, receiptsPath, toast);
    }
  }, [appointment, invoicesPath, receiptsPath]);

  useEffect(() => {
    setInvOrRecExist((prev) => ({
      ...prev,
      fullInv: !!fullInvoiceFile.find((f) => f.name.slice(-1) !== "/"),
    }));
  }, [fullInvoiceFile]);
  state.locations.find((loc) => loc.lid === state.selectedLocation);
  return (
    <Accordion index={open ? [0] : []} my="4" allowToggle>
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            color={"#5754FF"}
            borderBottom={"1px solid"}
            borderBottomColor={"#5754FF"}
            fontWeight={500}
            fontSize={"13px"}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <Box as="span" flex="1" textAlign="left">
              Payment
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <div className="w-full space-y-1">
            <div className="bg-[#D2D2FF] w-full p-5 flex flex-col items-center rounded-lg">
              <ClinicAidSection
                appointment={appointment}
                state={state}
                sendingAidClaim={sendingAidClaim}
                setSendingAidClaim={setSendingAidClaim}
                setPreview={setPreview}
                toast={toast}
              />
              {preview && (
                <PreviewFile
                  preview={preview}
                  setPreview={setPreview}
                  appointment={appointment}
                  state={state}
                />
              )}
              <button
                disabled={
                  appointment?.cancelled || appointment?.paidByMembership
                }
                onClick={(e) => {
                  if (!appointment?.cancelled) setAmountModal(true);
                }}
                className={`focus:outline-none disabled:cursor-not-allowed my-2 mx-10 bg-[#5754FF] text-white flex justify-center items-center   rounded-md p-2 px-10  whitespace-nowrap`}
              >
                <span className="font-medium text-sm">Update Criteria</span>
              </button>
              <div className="w-full flex justify-between space-x-4 my-2 px-[2px]">
                {
                  <button
                    onClick={(e) => {
                      let file = fullInvoiceFile.find(
                        (f) => f.name.slice(-1) !== "/"
                      );
                      if (!fetchingData && appointment && file) {
                        getFileData(
                          appointment,
                          state,
                          invoicesPath,
                          file.name,
                          setPreview,
                          toast,
                          setFetchingData,
                          "full",
                          "invoice"
                        );
                      }
                    }}
                    disabled={
                      !fullInvoiceFile.find((f) => f.name.slice(-1) !== "/")
                    }
                    className={`focus:outline-none mb-[1rem] bg-[#fFF] text-[#5754FF] disabled:cursor-not-allowed flex justify-center items-center rounded-md p-2  w-full whitespace-nowrap`}
                  >
                    <span className="font-medium text-sm">
                      View Full Invoice
                    </span>
                  </button>
                }
                {/*<PrintInvRec {...{appointment, state}} />*/}
                <button
                  onClick={(e) => {
                    let file = fullReceiptFile.find(
                      (f) => f.name.slice(-1) !== "/"
                    );

                    if (!fetchingData && appointment && file) {
                      getFileData(
                        appointment,
                        state,
                        receiptsPath,
                        file.name,
                        setPreview,
                        toast,
                        setFetchingData,
                        "full",
                        "receipt"
                      );
                    }
                  }}
                  className={`focus:outline-none mb-[1rem] disabled:cursor-not-allowed  text-[#03A700] flex justify-center items-center bg-transparent ring-[1px] active:ring-0 ring-[#03A700]  rounded-md p-2  w-full whitespace-nowrap`}
                  disabled={
                    !fullReceiptFile.find((f) => f.name.slice(-1) !== "/")
                  }
                >
                  <span className="font-medium text-sm">View Full Reciept</span>
                </button>
              </div>
              <div className="space-y-3 flex flex-col  min-w-full min-h-[22rem] relative">
                <PatientPayment
                  {...{
                    appointment: appt,
                    state,
                    dispatch,
                    setInvOrRecExist,
                    invOrRecExist,
                  }}
                />

                <TppPayment
                  {...{
                    appointment: appt,
                    state,
                    dispatch,
                    setInvOrRecExist,
                    invOrRecExist,
                  }}
                />
                {(ptInv === false ||
                  fullInv === false ||
                  (tppInv === false && appointment?.tpp)) && (
                  <Button
                    variant="outline"
                    size={"sm"}
                    isLoading={creatingInv}
                    colorScheme="blue"
                    type="submit"
                    my={4}
                    w="full"
                    px="10"
                    onClick={async () => {
                      if (creatingInv) return;
                      setCreatingInv(true);
                      await initialInvoices(appt, state);
                      dispatch({
                        type: "SELECT_APPOINTMENT",
                        payload: {...appt},
                      });
                      setCreatingInv(false);
                    }}
                  >
                    Reload Invoices
                  </Button>
                )}
              </div>
              {amountModal && appointment && !appointment?.cancelled && (
                <ChangeAmountModal
                  state={state}
                  appointment={appointment}
                  stateChanger={setAmountModal}
                  dispatch={dispatch}
                  socket={socket}
                />
              )}
            </div>
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function appointmentStatus(appt) {
  let status = [
    "noShow",
    "cancelled",
    "arrived",
    "pConfirmed",
    "unconfirmed",
    "rescheduled",
  ];

  for (let val of status) {
    if (appt[val]) return val;
  }
  return "unconfirmed";
}
