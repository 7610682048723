import {Icon, Box, Badge, VStack, Text, Tooltip, Divider, Flex, IconButton} from "@chakra-ui/react";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {DeleteIcon} from "@chakra-ui/icons";

export const AssignedServiceForms = ({
  assignedForms,
  setAssignedForms,
  setAssignedFormsOptions
}) => {

  return (
    <Box h="45%" w="100%" overflowY="scroll">
      <Badge
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        mb="4"
        colorScheme="purple"
      >
        <Icon as={AiOutlineCheckCircle} fontSize="15px" mt="-0.5" />{" "}
        {assignedForms?.length === 0 && "No "} Assigned Forms
      </Badge>
      {assignedForms?.length > 0 && (
        <VStack align="stretch" w="100%">
          {assignedForms?.map((form, i) => {
            return (
              <Box key={i}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Tooltip label={form.formTitle} openDelay={300}>
                    <Text 
                      textAlign="left" 
                      width="100%" 
                      isTruncated 
                      mr={2} 
                      fontSize="16px"
                      fontWeight="medium"
                      color="gray.500"
                    >
                      {form.formTitle}
                    </Text>
                  </Tooltip>
                  <IconButton
                    aria-label="Delete form"
                    icon={<DeleteIcon />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => {
                      setAssignedForms((prev) => {
                        const newForms = prev.filter((f) => f.fid !== form.fid);
                        return newForms;
                      });
                      setAssignedFormsOptions((prev) => ({
                        ...prev,
                        assignedFormsId: prev.assignedFormsId.filter((f) => f !== form.fid)
                      }));
                    }}
                  />
                </Flex>
                <Divider />
              </Box>
            );
          })}
        </VStack>
      )}{" "}
    </Box>
  );
}
