import {Box} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";
import {crud} from "../crudRequests";
import {AssignedServiceForms} from "./AssignedServiceForms";
import {AssignServiceForm} from "./AssignServiceForm";
import {createHashTable} from "../utils/createHashTable";
import {AssignedServiceFormSwitch} from "./AssignedServiceFormSwitch";

export const ServiceForms = ({
  schState,
  selectedService,
  setAssignedFormsOptions,
  assignedFormsOptions,
  setWarning,
}) => {
  const [forms, setForms] = useState([]);
  const [assignedForms, setAssignedForms] = useState([]);
  const selectedServicesForms = useMemo(
    () => new Set(Array.isArray(selectedService?.forms) ? selectedService.forms : []),
    [selectedService?.forms]
  );
  const formsHash = useMemo(() => createHashTable(forms, "fid"), [forms]);

  useEffect(() => {
    const getForms = async () => {
      const res = await crud(schState, [
        {
          db: schState.db,
          collection: "forms",
          parameters: [{}],
          method: "find",
        },
      ]);
      const fetchedForms = res.data[0] || [];
      const filteredForms = fetchedForms.filter(form => selectedServicesForms.has(form.fid));
      setForms(fetchedForms);
      setAssignedForms(filteredForms);
    };
    getForms();
  }, []);

  return (
    <Box
      shadow="md"
      p="5"
      rounded="3xl"
      minH="370px"
      bgGradient="linear(to-b, #2d29ff44, #7b79ff0f)"
      spacing="2"
      w="25%"
      overflowY="scroll"
    >
      <Box h="10%">
        <h1 className="text-[#5754FF] font-semibold text-2xl text-center">
          {"Forms"}
        </h1>
      </Box>
      <AssignedServiceForms
        assignedForms={assignedForms}
        setAssignedForms={setAssignedForms}
        setAssignedFormsOptions={setAssignedFormsOptions}
      />
      <AssignedServiceFormSwitch
        firstAppointmentOnly={assignedFormsOptions.firstAppointmentOnly}
        setAssignedFormsOptions={setAssignedFormsOptions}
      />
      <AssignServiceForm
        forms={forms}
        formsHash={formsHash}
        setAssignedFormsOptions={setAssignedFormsOptions}
        setAssignedForms={setAssignedForms}
        assignedFormsId={assignedFormsOptions.assignedFormsId}
        setWarning={setWarning}
      />
    </Box>
  );
};
