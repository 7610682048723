import {crud} from "../../../../../crudRequests";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";
export default async function connectNote({
  note,
  patientId,
  appointmentId,
  connectNote,
  state,
}) {
  try {
    if (note) {
      await crud(state, [
        {
          db: state.db,
          collection: "patientNotes",
          parameters: [
            {noteId: note.noteId},
            {$set: {aid: connectNote ? appointmentId : null}},
          ],
          method: "updateOne",
        },
      ]);
    }
  } catch (error) {
    throw error;
  }
}
