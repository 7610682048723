import {update} from "react-querybuilder";
import {crud} from "../../../../../crudRequests";
import {v4 as uuidv4} from "uuid";
export default async function saveNote({
  payload,
  selectedNote,
  state,
  type,
  dispatch,
  appointmentId,
  patientId,
}) {
  try {
    let userId =
      state.userType === "doctor" ? state?.doctor?.did : state?.admin?.id;

    if (selectedNote) {
      await crud(state, [
        {
          db: state.db,
          collection:
            type === "appointment" ? "appointmentNotes" : "patientNotes",
          parameters: [
            {noteId: selectedNote.noteId},
            {$set: {...payload, userId, updatedAt: Date.now()}},
          ],
          method: "updateOne",
        },
      ]);
    } else {
      let newNote = {
        noteId: uuidv4(),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        userType: state.userType,
        userId,
        ...payload,
      };

      if (type === "patient") {
        newNote.pid = patientId;
        newNote.keyNote = false;
      } else {
        newNote.aid = appointmentId;
      }

      let res = await crud(state, [
        {
          db: state.db,
          collection:
            type === "appointment" ? "appointmentNotes" : "patientNotes",
          parameters: [newNote],
          method: "insertOne",
        },
      ]);
    }
  } catch (error) {
    throw error;
  }
}
