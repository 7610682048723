import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Switch,
  Icon,
  Text,
  VStack,
  Textarea,
  Button,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  Select,
} from "@chakra-ui/react";
import React, {useState, useRef, useEffect} from "react";
import {TbReportMoney} from "react-icons/tb";
import SelectGiftCard from "./SelectGiftCard";
import {v4 as uuidv4} from "uuid";
import saleProducts from "../../Products/helpers/saleProducts";
import {ProductCardPaymentModal} from "./ProductCardPaymentModal";
import {crud} from "../../../crudRequests";

async function updateGiftCardBalance(state, selectedGiftCard, amount) {
  if (!selectedGiftCard) {
    throw new Error("No gift card selected");
  }

  const newBalance = selectedGiftCard.balance - amount;
  if (newBalance < 0) {
    throw new Error("Insufficient balance on the gift card");
  }

  await crud(state, [
    {
      db: state.db,
      collection: "giftCards",
      parameters: [
        {gcId: selectedGiftCard.gcId},
        {$set: {balance: newBalance}},
        {returnNewDocument: true},
      ],
      method: "findOneAndUpdate",
    },
  ]);
}
const id = (n) => {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
};

export const processProductPayment = async ({
  amount,
  paymentMethod,
  description,
  selectedGiftCard,
  state,
  toast,
  selectedProducts,
  patient,
  productSaleClinicianId,
}) => {
  const invoiceId = id(8);
  const receiptId = id(8);

  const transaction = {
    type: "product",
    tid: uuidv4(),
    invoiceId,
    receiptId,
    lid: state.selectedLocation,
    pid: patient?.pid,
    pName: `${patient?.fName} ${patient?.lName}`,
    pEmail: patient?.email,
    amount: parseFloat(amount),
    productAmount: parseFloat(amount),
    productTax:
      state.locations?.find((l) => l.lid === state.selectedLocation)
        ?.productsTaxRate || 0,
    pending: false,
    cancelled: false,
    paid: parseFloat(amount),
    products: selectedProducts,
    tppAmount: 0,
    patientAmount: parseFloat(amount),
    amountPaidByPatient: parseFloat(amount),
    amountPaidByTpp: 0,
    patientPaymentStatus: "paid",
    paymentStatus: "paid",
    serviceDate: new Date().getTime(),
    serviceType: "Product Sale",
    paymentMethod,
    description,
    note: description,
    giftCardId: selectedGiftCard?.id,
    productSaleClinicianId,
  };

  if (state.userType === "doctor") {
    transaction.did = state.doctor?.did;
  }

  if (paymentMethod === "giftCard") {
    await updateGiftCardBalance(state, selectedGiftCard, amount);
  }

  await saleProducts(
    transaction,
    () => {},
    () => {},
    state.patient,
    state,
    () => {},
    toast
  );
  return transaction;
};

export function ProductPaymentModal({
  isOpen,
  onClose,
  amount,
  state,
  toast,
  selectedProducts,
  patient,
  productSaleClinicianId,
}) {
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [method, setMethod] = useState("cash");
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const initialRef = useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <HStack gap={1}>
            <Icon
              className="show-icon"
              boxSize={6}
              color="blue.500"
              as={TbReportMoney}
            />
            <Text
              fontSize={"2xl"}
              color="gray.800"
              textAlign={"center"}
              fontWeight={600}
            >
              {"Product Payment"}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ProductCardPaymentModal
          patient={patient}
          appointment={{}}
          state={state}
          open={cardModalOpen}
          setOpen={setCardModalOpen}
          onClose={onClose}
          transaction={{
            amount: amount,
            paymentMethod: "creditCard",
            products: selectedProducts,
          }}
        />
        <ModalBody pt="0">
          <form
            id="product-payment-form"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                if (method === "creditCard") {
                  setCardModalOpen(true);
                  return;
                }
                setIsLoading(true);

                const transaction = await processProductPayment({
                  amount,
                  paymentMethod: method,
                  description,
                  selectedGiftCard,
                  state,
                  toast,
                  selectedProducts,
                  patient,
                  productSaleClinicianId,
                });

                transaction.patientPaymentStatus = "paid";
                transaction.paymentStatus = "paid";

                setDescription("");
                setMethod("cash");
                setSelectedGiftCard(null);
                onClose();
              } catch (e) {
                toast({
                  title: "Payment Error",
                  description: e.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <VStack gap={3}>
              <Textarea
                ref={initialRef}
                required
                resize={"vertical"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={"Type the description..."}
                size="sm"
              />
              <HStack w="full" align={"self-start"} gap={4}>
                <FormControl id="method" isRequired flex={1}>
                  <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
                    Method
                  </FormLabel>
                  <Select
                    variant={"outline"}
                    size={"sm"}
                    rounded={"md"}
                    w={"full"}
                    value={method}
                    onChange={(e) => {
                      let mtd = e.target.value;
                      if (mtd !== "giftCard") setSelectedGiftCard(null);
                      setMethod(mtd);
                    }}
                  >
                    <option value={"cash"}>Cash</option>
                    <option value={"check"}>Check</option>
                    <option value={"giftCard"}>Gift Card</option>
                    <option value={"creditCard"}>Credit Card</option>
                  </Select>
                </FormControl>
              </HStack>

              {method === "giftCard" && (
                <SelectGiftCard
                  {...{state, setSelectedGiftCard, selectedGiftCard}}
                />
              )}
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            type="submit"
            form="product-payment-form"
            colorScheme="blue"
            mr={3}
          >
            Confirm Payment
          </Button>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
