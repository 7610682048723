import axios from "axios";
import {showToast} from "./toastService";
export const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : /staticapps/.test(window.location.href)
  ? "https://blh-staging-server-9ae280ee4ef8.herokuapp.com"
  : "https://blh-server-2001610adcd9.herokuapp.com";
//export const url = "https://blh-staging-server-9ae280ee4ef8.herokuapp.com";
//export const url = "https://blh-server-2001610adcd9.herokuapp.com";
//export const url = "http://localhost:3000";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch (error) {
    console.log(error);
    window.location.href = "../login";
  }
}

window.tmId = 0;
window.sessionExpired = false;

export async function crud(state, requestObjs, options, signal) {
  let config = {
    method: "POST",
    url: `${url}/crud`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
      options,
    }),
    signal,
  };
  let axiosResponse = axios(config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("client error:", error);
      if (error?.response?.status === 403 && !window.sessionExpired) {
        window.sessionExpired = true;
        showToast({
          title: "Session expired.",
          status: "error",
          description: "Your session has expired, please log in again.",
          duration: 10000,
          isClosable: true,
          position: "bottom",
        });

        clearTimeout(window.tmId);
        window.tmId = setTimeout(() => {
          window.location.href = "../login";
        }, 10000);
      }
      return [];
      //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });

  return axiosResponse;
}

export async function supplementaryCrud(state, requestObjs, options, signal) {
  let config = {
    method: "POST",
    url: `${url}/supplementaryCrud`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
    }),
    signal,
  };
  return axios(config);
}

export async function loadDataForDoctorLogin(state, navigate, loader, signal) {
  loader && loader("animate-spin");
  try {
    let location = state.selectedLocation || state.doctor.lid?.[0];

    let did = state.doctor?.did;

    let pParameters = state.doctor?.access?.allPatients
      ? [
          {
            $or: [{lid: location}, {lid: ""}, {lid: {$size: 0}}],
          },
          {
            pid: 1,
            lid: 1,
            fName: 1,
            lName: 1,
            gender: 1,
            email: 1,
            phone: 1,
            referralVia: 1,
            did: 1,
            stpCustomerId: 1,
            healthCard: 1,
            dob: 1,
            tpp: 1,
            noOfInsuredSessions: 1,
            inactive: 1,
            deceased: 1,
            deleted: 1,
            memberships: 1,
            refGpId: 1,
            refClinicianId: 1,
            address: 1,
            city: 1,
            province: 1,
            postalCode: 1,
            country: 1,
            emergencyContactName: 1,
            emergencyContactEmail: 1,
            preferredPronoun: 1,
            address2: 1,
            address1: 1,
            mName: 1,
            preferredName: 1,
            phoneHome: 1,
            phoneAlternative: 1,
            emergencyContactPhone: 1,
            emergencyContactrRelation: 1,
            height: 1,
            weight: 1,
            medications: 1,
            allergies: 1,
            familyHealthProblems: 1,
            preferredClinicianId: 1,
            groupId: 1,
            serviceFormsAssigned: 1,
          },
        ]
      : [
          {
            $or: [
              {lid: location, did},
              {lid: "", did},
              {lid: {$size: 0}, did},
            ],
          },
        ];

    let requestObjs = [
      {
        db: state.db || "blh",
        collection: "patients",
        parameters: pParameters,
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "appointments",
        parameters: [{lid: location, did}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "services",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "notifications",
        parameters: [{userType: "doctor", [did]: {$exists: true}}, {[did]: 1}],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "doctors",
        parameters: [{email: state.doctor?.email}],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "locations",
        parameters: [{lid: {$in: state.doctor?.lid}}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "insurers",
        parameters: [{}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "serviceCriteria",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "provider_types",
        parameters: [{}],
        method: "find",
      },
    ];

    let requestObjs2 = [
      {
        db: "customers",
        collection: "organizations",
        parameters: [
          {db: state.db},
          {stpSecretKey: 0, clinicAidPassword: 0, _id: 0},
        ],
        method: "findOne",
      },
    ];

    let res = await crud(state, requestObjs, null, signal);
    let res2 = await crud(state, requestObjs2, null, signal);
    let data = res?.data || [];
    let data2 = res2?.data || [];
    loader && loader("");

    navigate(state.destination, {
      state: {
        ...state,
        patients: data[0] || [],
        appointments: data[1] || [],
        services: data[2] || [],
        notifications: data[3] ? data[3][did] : [],
        doctor: data[4],
        selectedLocation: location,
        locations: data[5] || [],
        insurers: data[6] || [],
        serviceCriteria: data[7] || [],
        providerTypes: data[8] || [],
        changingLocation: false,
        organization: data2[0] || [],
        parsed: false,
      },
    });
  } catch (error) {
    console.log(error.message);
  }
}

export async function loadDataForAdminLogin(state, navigate, loader, signal) {
  loader && loader("animate-spin");
  /*let start = Date.now();
  console.log("INITIAL_REQUEST");*/
  try {
    let location = state.selectedLocation || state.admin.location?.[0];
    let oid = state.admin?.oid;
    let userId = location + oid;

    let requestObjs = [
      {
        db: state.db || "blh",
        collection: "doctors",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "patients",
        parameters: [
          {
            $or: [{lid: location}, {lid: ""}, {lid: {$size: 0}}],
          },
          {
            pid: 1,
            did: 1,
            lid: 1,
            fName: 1,
            lName: 1,
            gender: 1,
            email: 1,
            phone: 1,
            dob: 1,
            referralVia: 1,
            stpCustomerId: 1,
            healthCard: 1,
            tpp: 1,
            noOfInsuredSessions: 1,
            inactive: 1,
            deceased: 1,
            deleted: 1,
            memberships: 1,
            refGpId: 1,
            refClinicianId: 1,
            address1: 1,
            address2: 1,
            city: 1,
            province: 1,
            postalCode: 1,
            country: 1,
            emergencyContactName: 1,
            emergencyContactEmail: 1,
            preferredPronoun: 1,
            mName: 1,
            preferredName: 1,
            phoneHome: 1,
            phoneAlternative: 1,
            emergencyContactPhone: 1,
            emergencyContactrRelation: 1,
            height: 1,
            weight: 1,
            medications: 1,
            allergies: 1,
            familyHealthProblems: 1,
            preferredClinicianId: 1,
            groupId: 1,
            serviceFormsAssigned: 1,
          },
        ],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "appointments",
        parameters: [
          {lid: location},
          /* {
            aid: 1,
            tid: 1,
            pid: 1,
            pName: 1,
            pConfirmed: 1,
            did: 1,
            dName: 1,
            teleconference: 1,
            telephone: 1,
            address: 1,
            duration: 1,
            cancelled: 1,
            noShow: 1,
            arrved: 1,
            ISOdate: 1,
          },*/
        ],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "services",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "notifications",
        parameters: [
          {userType: "admin", [userId]: {$exists: true}},
          {[userId]: 1},
        ],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "admins",
        parameters: [{email: state.admin?.email}],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "locations",
        parameters: [{lid: {$in: state.admin?.location || []}}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "insurers",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "serviceCriteria",
        parameters: [{lid: location}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "provider_types",
        parameters: [{lid: location}],
        method: "find",
      },
    ];
    let requestObjs2 = [
      {
        db: "customers",
        collection: "organizations",
        parameters: [
          {db: state.db},
          {stpSecretKey: 0, clinicAidPassword: 0, _id: 0},
        ],
        method: "findOne",
      },
    ];
    //console.log(location)
    let res = await crud(state, requestObjs, null, signal);
    let res2 = await crud(state, requestObjs2, null, signal);
    // console.log("loadData: ", res, res2);
    let data = res?.data || [];
    let data2 = res2?.data || [];
    loader && loader("");

    navigate(state.destination, {
      state: {
        ...state,
        doctors: data[0] || [],
        patients: data[1] || [],
        appointments: data[2] || [],
        services: data[3] || [],
        notifications: data[4] ? data[4][userId] : [],
        admin: data[5],
        selectedLocation: location,
        locations: data[6] || [],
        insurers: data[7] || [],
        serviceCriteria: data[8] || [],
        providerTypes: data[9] || [],
        changingLocation: false,
        organization: data2[0] || [],
        parsed: false,
      },
    });
  } catch (error) {
    console.log(error.message);
  }
}

export async function addPatient(state, addBody, options) {
  let configPatient = {
    method: "POST",
    url: `${url}/addPatient`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      addBody: addBody,
      db: state.db,
      options,
      org: state.organization,
    }),
  };
  return axios(configPatient);
}

export async function sendWelcomeMessage(state, user, userType) {
  let configPatient = {
    method: "POST",
    url: `${url}/sendWelcomeMessage`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      user,
      db: state.db,
      org: state.organization,
      userType,
    }),
  };
  return axios(configPatient);
}

export async function addRelationship(state, addBody, options) {
  let configRelation = {
    method: "POST",
    url: `${url}/addRelationship`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      addBody,
      db: state.db,
      options,
      org: state.organization,
    }),
  };
  return axios(configRelation);
}

export async function addBookNowPatient(state, addBody, options) {
  let configPatient = {
    method: "POST",
    url: `${url}/addBookNowPatient`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      addBody: addBody,
      db: state.db,
      options,
      org: state.organization,
    }),
  };
  return axios(configPatient);
}

export async function addDoctor(state, addBody) {
  let configPatient = {
    method: "POST",
    url: `${url}/addDoctor`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      addBody: addBody,
      db: state.db,
      org: state.organization,
    }),
  };
  return axios(configPatient)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "../login";
    });
}

export async function viewOrganizationByUrl(u) {
  console.log(`${url}/viewOrganizationByUrl`);
  let configOrganization = {
    method: "POST",
    url: `${url}/viewOrganizationByUrl`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({url: u?.replace("www.", "")}),
  };
  return axios(configOrganization)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "../login";
    });
}

export async function excelTransactionsReport(state, table, location) {
  let configTable = {
    method: "POST",
    url: `${url}/excelTransactionsReport`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      table,
      location,
      user: state.admin || state.doctor,
    }),
  };
  return axios(configTable);
}

/*export async function generatePaymentLink(state, content, attachment) {
  let config = {
    method: "POST",
    url: `${url}/generatePaymentLink`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      attachment,
    }),
  };
  return axios(config);
}*/

export async function sendInvRecEmail(state, content) {
  let config = {
    method: "POST",
    url: `${url}/sendInvRec`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
    }),
  };
  return axios(config);
}

export async function sendPatientFileEmail(state, content) {
  let config = {
    method: "POST",
    url: `${url}/sendPatientFileEmail`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
    }),
  };
  return axios(config);
}

export async function sendAltMeetingLinkEmail(state, content) {
  let config = {
    method: "POST",
    url: `${url}/sendAltMeetingLinkEmail`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
    }),
  };
  return axios(config);
}

export async function speakerDiarization(
  state,
  audio,
  patientData,
  onUploadProgress
) {
  const formData = new FormData();
  formData.append("audioData", audio, "audio.wav");
  formData.append("pid", patientData.pid);
  formData.append("db", state.db);
  formData.append("dName", state.doctor?.name || state.admin?.name);

  let config = {
    method: "POST",
    url: `${url}/speakerDiarization`,
    headers: {
      "Content-Type": "multipart/form-data", // Ajusta el tipo MIME según tus necesidades
    },
    data: formData,
    onUploadProgress,
  };
  return axios(config);
}

export async function processSummary(state, sid, type) {
  return axios({
    method: "POST",
    url: `${url}/processSummary`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      db: state.db,
      sid: sid,
      type: type,
    }),
  });
}

export async function sendCardReqLink(state, patient) {
  let sender = state?.admin ? state?.organization?.name : state?.doctor.name;
  let location;

  try {
    location = await crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [{lid: state.selectedLocation}],
        method: "findOne",
      },
    ]);
    location = location.data[0];
    sender = location?.name || sender;
  } catch (error) {}

  let config = {
    method: "POST",
    url: `${url}/sendCardReqLink`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      patient,
      db: state.db,
      sender,
    }),
  };
  return axios(config);
}

export async function paymentIntent() {
  let config = {
    method: "POST",
    url: `${url}/create-payment-intent`,
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({}),
  };
  return axios(config);
}

export async function createCharge(state, content, cardId) {
  let config = {
    method: "POST",
    url: `${url}/createCharge`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      cardId,
    }),
  };
  return axios(config);
}

export async function changePassword(state, updateBody) {
  let configNotifications = {
    method: "POST",
    url: `${url}/changePassword`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      updateBody: updateBody,
    }),
  };

  return await axios(configNotifications);
}

export async function bookNowData(state, navigate, loader, signal) {
  loader && loader("animate-spin");

  try {
    let org = await viewOrganizationByUrl(
      /localhost/.test(window.location.host)
        ? "clinic.brightlight.ai"
        : window.location.host
    );

    //console.log(org);

    if (org) {
      const pathSegments = window.location.pathname.split("/");
      let location = await bookNowCrud(
        state,
        [
          {
            db: org?.db || "blh",
            collection: "locations",
            parameters: [{lid: pathSegments[pathSegments.length - 1]}],
            method: "findOne",
          },
        ],
        null,
        signal
      );

      location = location.data[0];

      if (location && org) {
        let query = {lid: location.lid};

        let requestObjs = [
          {
            db: org.db || "blh",
            collection: "doctors",
            parameters: [query],
            method: "find",
          },
          {
            db: org.db || "blh",
            collection: "services",
            parameters: [query],
            method: "find",
          },
        ];

        //console.log(location)
        let res = await bookNowCrud(state, requestObjs, null, signal);

        let data = res.data;
        loader && loader("");
        let selectedThread = state.selectedThread || "home";

        navigate(state.destination, {
          state: {
            ...state,
            doctors: data[0] || [],
            services: data[1] || [],
            organization: org,
            location,
            selectedThread,
            db: org.db,
          },
        });
      } else {
        navigate(state.destination, {
          state: {...state, loadingData: false},
        });
      }
    }
  } catch (error) {
    console.log(error.message);
    //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
  }
}

export async function bookNowCrud(state, requestObjs, options, signal) {
  let configService = {
    method: "POST",
    url: `${url}/bookNowCrud`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      requestObjs: requestObjs,
      options,
    }),
    signal,
  };
  return axios(configService);
}

export async function fax(state, toNumber, fileUrl, fromNumber) {
  console.log({jwt: state.jwt});
  let configService = {
    method: "POST",
    url: `${url}/fax`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      toNumber,
      fileUrl,
      fromNumber,
    }),
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export async function drugAutocomplete(state, query) {
  let configService = {
    method: "GET",
    url: `${url}/drugAutocomplete?query=${query}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    return res;
  });
}
export async function pharmAutocomplete(state, query) {
  let configService = {
    method: "GET",
    url: `${url}/pharmAutocomplete?query=${query}&lid=${state.selectedLocation}&oid=${state?.organization?.oid}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}
export async function deletePharmacy(state, phid) {
  let configService = {
    method: "GET",
    url: `${url}/deletePharmacy?phid=${phid}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}
export async function insertPharmacy(state, data) {
  let configService = {
    method: "POST",
    url: `${url}/insertPharmacy`,
    data: {data},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}
export async function updatePharmacy(state, phid, data) {
  let configService = {
    method: "POST",
    url: `${url}/updatePharmacy`,
    data: {phid, data},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
  };
  return axios(configService).then((res) => {
    console.log({res});
    return res;
  });
}

export async function getPaymentMethods(state, pid) {
  let configService = {
    method: "POST",
    url: `${url}/getPaymentMethods`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function setDefaultPaymentMethods(state, pid, paymentId) {
  let configService = {
    method: "POST",
    url: `${url}/setDefaultPaymentMethods`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      paymentId,
      id: jwtDecode(state.jwt).id,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}
export async function createPaymentMethod(state, pid, paymentId) {
  let configService = {
    method: "POST",
    url: `${url}/createPaymentMethod`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      pid,
      db: state.db,
      paymentId,
      id: jwtDecode(state.jwt).id,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService).then((res) => {
    return res;
  });
}

export async function executePayment(
  state,
  content,
  pid,
  paymentMethod,
  payment_intent_id
) {
  let configService = {
    method: "POST",
    url: `${url}/executePayment`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      pid,
      paymentMethod,
      db: state.db,
      payment_intent_id,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService);
}

export async function executeMembershipPayment(
  state,
  content,
  pid,
  paymentMethod,
  payment_intent_id
) {
  let configService = {
    method: "POST",
    url: `${url}/executeMembershipPayment`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      pid,
      paymentMethod,
      db: state.db,
      payment_intent_id,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService);
}

export async function executeProductPayment(
  state,
  content,
  pid,
  paymentMethod,
  payment_intent_id
) {
  let configService = {
    method: "POST",
    url: `${url}/executeProductPayment`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      content,
      pid,
      paymentMethod,
      db: state.db,
      payment_intent_id,
      lid: state.selectedLocation,
    }),
  };
  return axios(configService);
}

export const loadAreaCodes = (
  schState,
  toll_free = false,
  country = "CA",
  state = "ON"
) => {
  let config = {
    method: "get",
    url: url + "/getAreaCodes",
    params: {
      toll_free,
      country,
      state,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + schState.jwt,
    },
  };

  return axios.request(config).catch((error) => {
    console.log({error});
  });
};

export const provisionNumber = (
  schState,
  lid,
  oid,
  country_code = "CA",
  area_code = "ON"
) => {
  if (typeof lid === "undefined" || typeof oid === "undefined")
    return console.log("lid or oid is undefined");

  console.log({area_code, country_code, oid, lid});

  let config = {
    method: "post",
    url: url + "/provisionNumber",
    data: {
      lid,
      oid,
      country_code,
      area_code,
      db: schState?.db,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + schState.jwt,
    },
  };

  return axios.request(config).catch((error) => {
    console.log({error});
  });
};

export const queryFaxes = async (
  schState,
  number,
  direction,
  created_before,
  created_after,
  initialRequest = true
) => {
  if (typeof number === "undefined" || !number)
    return console.log("number is undefined");
  let config = {
    method: "get",
    url: url + "/get-faxes",
    params: {number, direction, created_before, created_after, initialRequest},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + schState.jwt,
    },
  };

  return axios.request(config).catch((error) => {
    console.log({error});
  });
};
export const getNotificationFaxes = async (
  schState,
  db,
  number,
  created_before,
  created_after
) => {
  if (typeof number === "undefined" || !number)
    return console.log("number is undefined");
  let config = {
    method: "get",
    url: url + "/get-notification-faxes",
    params: {db, number, created_before, created_after},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + schState.jwt,
    },
  };

  return axios.request(config).catch((error) => {
    console.log({error});
  });
};
export const queryFaxFile = (schState, id, thumbnail) => {
  if (typeof id === "undefined" || !id) return console.log("id is undefined");
  let config = {
    method: "get",
    url: url + "/get-file",
    params: {id, thumbnail},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + schState.jwt,
    },
    responseType: "arraybuffer",
  };

  return axios.request(config).catch((error) => {
    console.log({error});
  });
};



export async function clinicAidClaim(
  state,
  aid,
  codes,
  claimDate,
  referring_provider_number,
  total
) {
  let config = {
    method: "POST",
    url: `${url}/clinicAidClaim`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      aid,
      db: state.db,
      codes,
      claimDate,
      referring_provider_number,
      total,
      organization: state.organization,
    }),
  };
  return axios(config);
}

export async function getClinicAidCodes(state, codeType, search_string, limit) {
  let config = {
    method: "POST",
    url: `${url}/getClinicAidCodes`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    data: JSON.stringify({
      id: state.jwt ? jwtDecode(state.jwt).id : "",
      codeType,
      db: state.db,
      search_string: search_string,
      limit: limit,
    }),
  };
  return axios(config);
}

export async function getPatientAidEligibility({
  state,
  health_number,
  ontario_version_code,
  signal,
}) {
  let config = {
    method: "GET",
    url: `${url}/getPatientAidEligibility`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt || "",
    },
    params: {db: state.db, health_number, ontario_version_code},
    signal,
  };
  return axios(config);
}

export async function apiAction(state, data, options, signal) {
  let config = {
    method: "POST",
    url: `${url}/api-action`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      s3BucketName: state.organization?.s3BucketName,
      db: state.db,
      data,
      options,
    }),
    signal,
  };
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("client error", error.message);
      if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });
}

export const deleteFax = async (schState, faxId, attached) => {
  if (!faxId) return console.log("faxId is undefined");
  let config = {
    method: "delete",
    url: `${url}/delete-fax/${faxId}`,
    data: {
      id: schState.jwt ? jwtDecode(schState.jwt).id : "",
      db: schState.db,
      attached: attached,
    },
    headers: {
      "Content-Type": "application/json", 
      Authorization: "Bearer " + schState.jwt,
    },
  };

  return axios(config).catch((error) => {
    console.log({error});
  });
};

export async function sendAppointmentForms(state, options) {
  let config = {
    method: "POST",
    url: `${url}/sendAppointmentForms`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      options,
    }),
  };

  return axios(config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("Error sending appointment forms:", error.message);
      if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });
}


