import {update} from "react-querybuilder";
import {crud} from "../../../../../crudRequests";
import {v4 as uuidv4} from "uuid";
export default async function saveDGCodes({
  diagnosticCodesNotes,
  dispatch,
  appointmentId,
  state,
}) {
  try {
    let res = await crud(state, [
      {
        db: state.db,
        collection: "appointments",
        parameters: [
          {aid: appointmentId},
          {
            $set: {
              diagnosticCodesNotes: String(diagnosticCodesNotes).trim(),
              updatedAt: Date.now(),
            },
          },
          {returnNewDocument: true},
        ],
        method: "findOneAndUpdate",
      },
    ]);

    res = res.data[0];
    dispatch({
      type: "UPDATE_APPOINTMENT",
      payload: res,
    });
    return;
  } catch (error) {
    throw error;
  }
}
