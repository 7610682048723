import {crud} from "../../../../../crudRequests";
import Swal from "sweetalert2";
export default async function deleteNote({
  selectedNote,
  appointmentId,
  state,
  type,

  dispatch,
  action,
}) {
  try {
    if (selectedNote) {
      let result = await Swal.fire({
        title: "Are you sure you want to delete this note?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        didOpen: () => {
          // Agregar listeners al abrir el modal
          const confirmButton = Swal.getConfirmButton();
          const cancelButton = Swal.getCancelButton();

          if (confirmButton) {
            confirmButton.addEventListener("click", (event) => {
              event.stopPropagation();
            });
          }

          if (cancelButton) {
            cancelButton.addEventListener("click", (event) => {
              event.stopPropagation();
            });
          }
        },
      });
      if (result.isConfirmed) {
        await crud(state, [
          {
            db: state.db,
            collection:
              type === "appointment" ? "appointmentNotes" : "patientNotes",
            parameters: [{noteId: selectedNote.noteId}],
            method: "deleteOne",
          },
        ]);
        action?.();
        return;
      }
    }
  } catch (error) {
    throw error;
  }
}
