import {
  Avatar,
  Text,
  Flex,
  VStack,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";
import {EditIcon, DeleteIcon} from "@chakra-ui/icons";
import {useMemo, useState} from "react";
import {deleteNote} from "./helpers/deleteNote";
import {FaKey} from "react-icons/fa";
import {saveNote} from "./helpers/saveNote";
import {AlertDialogComponent} from "../../AlertDialogComponent";
export default function Note({
  setNotes,
  state,
  note,
  setSelectedNote,
  cliniciansMap,
  adminsMap,
  onOpen,
  setKeyNote,
  keyNote,
  isNoteTab,
}) {
  const [isLoading, setIsLoading] = useState();

  let user = useMemo(() => {
    switch (note.userType) {
      case "clinician":
        return cliniciansMap[note.userId];
      case "admin":
        return adminsMap[note.userId];

      default:
        return cliniciansMap[note.userId];
    }
  }, [note.userType]);
  const toast = useToast();

  const makeKeyNote = async (note) => {
    const isKeyNote = note.keyNote ?? false;
    const toastText = isKeyNote ? "Key Note removed" : "Key Note added";
    await saveNote(
      null,
      {keyNote: !isKeyNote},
      note,
      state,
      setIsLoading,
      toast,
      setNotes,
      toastText,
      keyNote
    );
    if (isKeyNote) {
      setKeyNote(null);
    } else {
      setKeyNote(note);
    }
  };

  return (
    <Card maxW="md" bg="gray.50">
      <CardHeader>
        <Flex
          spacing="4"
          justify={"space-between"}
          align={"flex-start"}
          w="100%"
        >
          <Flex gap="2" alignItems="center" w="50%">
            <Avatar showBorder size="md" name={user?.name} src={user?.photo} />

            <VStack textAlign={"start"} align={"self-start"} gap={0}>
              <Heading size="xs">{user?.name}</Heading>
              <Text fontSize={"xs"}>{user?.title}</Text>
            </VStack>
          </Flex>
          <Flex direction="column" alignItems="flex-end" w="50%">
            <Flex
              gap="1"
              fontSize={"xs"}
              color={"gray.500"}
              alignItems="center"
              flexWrap="wrap"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <Text>{"Created at:"}</Text>
              <Text color={"gray.700"} fontWeight={500}>
                {note?.createdAt
                  ? lightFormat(note?.createdAt, "dd/MM/yyyy hh:mm aaa")
                  : "-"}
              </Text>
            </Flex>
            {isNoteTab && (
              <Flex mt={2} gap={2}>
                {keyNote && keyNote.noteId !== note.noteId ? (
                  <AlertDialogComponent
                    variant={note.keyNote ? "solid" : "outline"}
                    icon={<FaKey />}
                    title="Change Key Note"
                    action={() => makeKeyNote(note)}
                    btnText="Yes"
                    size="xs"
                    body="There is already a key note selected. Are you sure you want to change the key note to this one?"
                    color="green"
                  />
                ) : (
                  <IconButton
                    size={"xs"}
                    aria-label="Key note"
                    icon={<FaKey />}
                    variant={note.keyNote ? "solid" : "outline"}
                    colorScheme="green"
                    onClick={() => makeKeyNote(note)}
                    isLoading={isLoading}
                  />
                )}
                <IconButton
                  size={"xs"}
                  aria-label="Edit note"
                  icon={<EditIcon />}
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => {
                    onOpen();
                    setSelectedNote(note);
                  }}
                />
                <IconButton
                  size={"xs"}
                  aria-label="Delete note"
                  icon={<DeleteIcon />}
                  variant="solid"
                  colorScheme="red"
                  onClick={() => deleteNote(note, state, setNotes, toast)}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody pt="0">
        <VStack align={"self-start"}>
          <Heading color={"blue.500"} size="sm" textAlign={"start"}>
            {note.title}
          </Heading>
          <Text w="full" fontSize="sm" textAlign={"start"}>
            {note.description}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
