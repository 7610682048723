import {useState} from "react";
import {Box, Button, FormControl, FormLabel} from "@chakra-ui/react";
import {SearchableSelect} from "./SearchableSelect";

export const AssignServiceForm = ({
  forms,
  formsHash,
  setAssignedForms,
  setAssignedFormsOptions,
  assignedFormsId,
  setWarning,
}) => {
  const [selectedForm, setSelectedForm] = useState();

  return (
    <Box maxW={"20rem"} h="35%">
      <FormControl id={"form"}>
        <FormLabel color="gray.500">Assign New Form</FormLabel>
        <SearchableSelect
          maxHeight="100px"
          borderRadius="1rem"
          isRequired={false}
          label="Select form"
          placeholder="-"
          options={[
            {value: "", label: "-"},
            ...forms
              .sort((a, b) =>
                a.formTitle
                  .toLowerCase()
                  .trim()
                  .localeCompare(b.formTitle.toLowerCase().trim())
              )
              .map((form) => ({
                value: form.fid,
                label: form.formTitle,
              })),
          ]}
          onChange={(e) => {
            setSelectedForm(e.value);
          }}
          value={selectedForm}
          isDisabled={false}
        />
      </FormControl>
      <Button
        _hover={{ bg: "#5e5bff" }}
        bg="#C1BFFF"
        color="white"
        type="submit"
        my={4}
        px="10"
        onClick={() => {
          if (!selectedForm){
            setWarning("Please select a form");
            return;
          }
          if (assignedFormsId.includes(selectedForm)) {
            setWarning("Form already assigned");
            return;
          }

          setAssignedForms((prev) => {
            const newForms = [...prev, formsHash[selectedForm]];
            return newForms;
          });
          setAssignedFormsOptions((prev) => ({
            ...prev,
            assignedFormsId: [...prev.assignedFormsId, selectedForm]
          }));
          setSelectedForm("");
        }}
      >
        Assign
      </Button>
    </Box>
  );
}
