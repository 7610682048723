import {crud} from "../../../../crudRequests";

export default async function fetchNotes(appointment, state, setNotes) {
  try {
    let res = await crud(state, [
      {
        db: state.db,
        collection: "patientNotes",
        parameters: [
          {
            $or: [
              {aid: appointment.aid},
              {pid: appointment.pid, keyNote: true},
            ],
          },
        ],
        method: "find",
        findCursor: [{method: "sort", arguments: [{createdAt: -1}]}],
      },
    ]);
    res = res.data;

    let notes = res[0] || [];

    notes = await Promise.all(
      notes.filter((e) => e.description).map((note) => setUserNote(note, state))
    );
    setNotes(notes);
  } catch (err) {
    setNotes([]);
  }
}

async function setUserNote(note, state) {
  let id = note.userId;
  let res = await crud(state, [
    {
      db: state.db,
      collection: "admins",
      parameters: [{id}],
      method: "findOne",
    },
    {
      db: state.db,
      collection: "doctors",
      parameters: [{did: id}],
      method: "findOne",
    },
    {
      db: state.db,
      collection: "patients",
      parameters: [{pid: id}],
      method: "findOne",
    },
  ]);
  res = res.data;
  let userData = res[0] || res[1] || res[2];
  let user = {};
  if (user) {
    user.name = res[0]
      ? res[0].name
      : userData
      ? `${userData.firstName || ""} ${userData.lastName || ""}`
      : null;
    user.title = userData.title;
  }
  note.user = user;
  return note;
}
