import {crud} from "../../../../../crudRequests";

export async function fetchNotes(setNotes, patientId, appointmentId, state) {
  try {
    let res = await crud(state, [
      {
        db: state.db,
        collection: "patientNotes",
        parameters: [{pid: patientId}],
        method: "find",
        findCursor: [{method: "sort", arguments: [{createdAt: -1}]}],
      },
    ]);
    res = res.data;

    let pNotes = res[0] || [];

    pNotes = await Promise.all(
      pNotes
        .filter((e) => e.description && (e.aid === appointmentId || !e.aid))
        .map((note) => setUserNote(note, state))
    );

    setNotes(pNotes);
  } catch (err) {
    console.log(err);
  }
}
export async function fetchPatientNotes(setPatientNotes, id, state) {
  try {
    let res = await crud(state, [
      {
        db: state.db,
        collection: "patientNotes",
        parameters: [{pid: id}],
        method: "find",
        findCursor: [{method: "sort", arguments: [{createdAt: -1}]}],
      },
    ]);
    res = res.data;

    let pNotes = res[0] || [];

    pNotes = await Promise.all(
      pNotes
        .filter((e) => e.description)
        .map((note) => setUserNote(note, state))
    );

    setPatientNotes(pNotes);
  } catch (err) {
    console.log(err);
  }
}
export async function fetchAppointmentNotes(setAppointmentNotes, id, state) {
  try {
    let res = await crud(state, [
      {
        db: state.db,
        collection: "appointmentNotes",
        parameters: [{aid: id}],
        method: "find",
        findCursor: [{method: "sort", arguments: [{createdAt: -1}]}],
      },
    ]);
    res = res.data;

    let aNotes = res[0] || [];

    aNotes = await Promise.all(
      aNotes
        .filter((e) => e.description)
        .map((note) => setUserNote(note, state))
    );

    setAppointmentNotes(aNotes);
  } catch (err) {
    console.log(err);
  }
}

async function setUserNote(note, state) {
  let id = note.userId;
  let res = await crud(state, [
    {
      db: state.db,
      collection: "admins",
      parameters: [{id}],
      method: "findOne",
    },
    {
      db: state.db,
      collection: "doctors",
      parameters: [{did: id}],
      method: "findOne",
    },
    {
      db: state.db,
      collection: "patients",
      parameters: [{pid: id}],
      method: "findOne",
    },
  ]);
  res = res.data;
  let userData = res[0] || res[1] || res[2];
  let user = {};
  if (user) {
    user.name = res[0]
      ? res[0].name
      : userData
      ? `${userData.firstName || ""} ${userData.lastName || ""}`
      : null;
    user.title = userData.title;
    user.photo = userData.photo;
  }
  note.user = user;
  return note;
}
