import React, {useState, useRef, useEffect} from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Button,
  Avatar,
  Text,
  Flex,
  VStack,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Heading,
  IconButton,
  useDisclosure,
  Tooltip,
  Badge,
  Stack,
  Collapse,
  HStack,
  Radio,
  RadioGroup,
  Icon,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Switch,
} from "@chakra-ui/react";
import {BsPlusCircleDotted} from "react-icons/bs";
import {lightFormat} from "date-fns";
import {EditIcon, DeleteIcon} from "@chakra-ui/icons";
import {RiUserReceived2Line} from "react-icons/ri";
import {AddIcon} from "@chakra-ui/icons";
import {fetchNotes} from "./helpers/fetchNotes";
import {MdEditNote, MdOutlineDeleteSweep} from "react-icons/md";
import {NotePanel} from "./NotePanel";
import {MdAccessTime} from "react-icons/md";
import saveNote from "./helpers/saveNote";
import saveDGCodes from "./helpers/saveDGCodes";
import deleteNote from "./helpers/deleteNote";
import makeKeyNote from "./helpers/makeKeyNote";
import connectNote from "./helpers/connectNote";
import {HiMiniKey} from "react-icons/hi2";
import makePatientNote from "../../helpers/makePatientNote";
export default function AppointmentNotes({appt, schState, dispatch, socket}) {
  const [patientNotes, setNotes] = useState([]);
  const [prevAppt, setPrevAppt] = useState(appt);

  const [tabIndex, setTabIndex] = useState(0);
  const [editDGCodes, setEditDGCodes] = useState(false);
  const [diagnosticCodesNotes, setDiagnosticCodesNotes] = useState(
    appt?.diagnosticCodesNotes || ""
  );
  const [loading, setLoading] = useState(false);

  if (prevAppt !== appt) {
    setPrevAppt(appt);
    setDiagnosticCodesNotes(appt?.diagnosticCodesNotes || "");
    setLoading(false);
    setEditDGCodes(false);
  }

  const saveDGNote = async () => {
    setLoading(true);
    await saveDGCodes({
      diagnosticCodesNotes,
      dispatch,
      appointmentId: appt?.aid,
      state: schState,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (appt) fetchNotes(setNotes, appt.pid, appt.aid, schState);
  }, [appt]);

  return (
    <div className="text-gray-700 space-y-1 mt-6 w-full ">
      <Tabs isFitted index={tabIndex} onChange={setTabIndex}>
        <TabList color={"#A1A1A1"} fontWeight={600}>
          <Tab fontSize={"0.8rem"}>Notes</Tab>
          <Tab fontSize={"0.8rem"}>Diagnostic Codes Notes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="2">
            <Notes
              key={"patient"}
              setTabIndex={setTabIndex}
              notes={patientNotes}
              setNotes={setNotes}
              type="patient"
              dispatch={dispatch}
              appt={appt}
              state={schState}
            />
          </TabPanel>
          <TabPanel p="0" pt="2">
            <NotePanel
              value={diagnosticCodesNotes}
              onChange={(e) => {
                appt?.diagnosticCodesNotes !== e.target.value &&
                  setEditDGCodes(true);
                setDiagnosticCodesNotes(e.target.value);
              }}
              saveNoteData={() => {
                saveDGNote(diagnosticCodesNotes);
              }}
              editDGCodes={editDGCodes}
              loading={loading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

function Notes({state, notes, type, setNotes, dispatch, appt, setTabIndex}) {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);

  const onMakeKeyNote = async (note) => {
    try {
      setNotes((prev) =>
        prev.map((e) => {
          if (e.noteId !== note.noteId) return {...e, keyNote: false};
          else return {...e, keyNote: !e.keyNote};
        })
      );
      await makeKeyNote({
        note,
        state,
      });
      if (type === "patient")
        await fetchNotes(setNotes, appt?.pid, appt?.aid, state);
    } catch (error) {
      console.log(error);
    }
  };
  const onConnectChange = async (note) => {
    try {
      setNotes((prev) =>
        prev.map((e) => {
          if (e.noteId !== note.noteId) return e;
          let aid = e.aid === appt?.aid ? "" : appt?.aid;
          return {...e, aid};
        })
      );
      await connectNote({
        note,
        patientId: note.pid,
        appointmentId: appt?.aid,
        connectNote: appt?.aid !== note.aid,
        state,
      });
      if (type === "patient")
        await fetchNotes(setNotes, appt?.pid, appt?.aid, state);
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (note) => {
    try {
      await deleteNote({
        selectedNote: note,
        appointmentId: appt?.aid,
        state,
        type,
        dispatch,
        action: () => {
          toast({
            title: "The note has been deleted!",
            status: "success",
            isClosable: true,
            duration: 2000,
          });
        },
      });
      setSelectedNote(null);

      if (type === "patient")
        await fetchNotes(setNotes, appt?.pid, appt?.aid, state);
    } catch (error) {}
  };

  return (
    <VStack
      textAlign="center"
      w="100%"
      py="4"
      pb="6"
      height={"full"}
      width={"full"}
      display="flex"
      flexWrap={"wrap"}
      alignItems={"center"}
      position={"relative"}
      gap={6}
      minH={"5rem"}
    >
      {isOpen && (
        <AddOrEdit
          {...{
            selectedNote,
            setSelectedNote,
            isOpen,
            setIsOpen,
            state,
            setNotes,

            type,
            dispatch,
            appointmentId: appt?.aid,
            patientId: appt?.pid,
          }}
        />
      )}

      <Tooltip
        hasArrow
        label="Add note"
        rounded={"md"}
        bg="white"
        color={"gray.600"}
        placement="top"
        fontWeight={"normal"}
      >
        <Box>
          <Icon
            cursor="pointer"
            onClick={() => {
              setSelectedNote(null);
              setIsOpen(true);
            }}
            as={BsPlusCircleDotted}
            w={6}
            h={6}
            mr={"0.5rem"}
          />
        </Box>
      </Tooltip>

      {notes.length > 0 && (
        <Box overflowY="auto" w="full" maxH={"20rem"}>
          <VStack gap="4">
            {notes?.map((note, i) => (
              <Note
                onConnectChange={onConnectChange}
                isApptNote={appt?.aid === note.aid}
                key={i}
                note={note}
                type={type}
                apptype={appt}
                onMakeKeyNote={() => onMakeKeyNote(note)}
                onEditSelection={(n) => {
                  setSelectedNote(n);
                  setIsOpen(true);
                }}
                onDeleteSelection={async (n) => {
                  setIsOpen(false);
                  await onDelete(n);
                }}
              />
            ))}
          </VStack>
        </Box>
      )}
    </VStack>
  );
}

function Note({
  note,
  onEditSelection,
  onDeleteSelection,
  onMakeKeyNote,
  isApptNote,
  onConnectChange,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  let user = note.user;

  let textLength = note.description?.length || 0;

  return (
    <Card w="full" bg="white">
      <CardHeader p="3">
        <Flex
          spacing="4"
          justify={"space-between"}
          align={"flex-start"}
          w="100%"
          flexWrap={"wrap"}
        >
          <Flex gap="2" flexWrap={"wrap"} alignItems="center" flex={1}>
            {user ? (
              <>
                <Avatar
                  showBorder
                  size="sm"
                  name={user?.name}
                  src={user?.photo}
                />

                <VStack textAlign={"start"} align={"self-start"} gap={0}>
                  <Heading whiteSpace={"pre-wrap"} size="xs">
                    {user?.name}
                  </Heading>
                  <Flex
                    gap="1"
                    fontSize={"xs"}
                    color={"gray.500"}
                    alignItems="center"
                    flexWrap="wrap"
                    display={{
                      base: "none",
                      md: "flex",
                    }}
                  >
                    <MdAccessTime className="text-gray-800" />
                    <Text color={"gray.700"} fontWeight={500}>
                      {note?.updatedAt
                        ? lightFormat(note?.updatedAt, "dd/MM/yyyy hh:mm aaa")
                        : "-"}
                    </Text>
                  </Flex>
                </VStack>
              </>
            ) : (
              <Heading
                color={"blue.500"}
                text
                size="sm"
                w="full"
                textAlign={"start"}
                whiteSpace={"pre-wrap"}
              >
                {note.title}
              </Heading>
            )}
          </Flex>
          <Stack direction="row" gap={1} align={"flex-start"}>
            <Tooltip
              hasArrow
              label={note.keyNote ? "Unset key" : "Set as key"}
              rounded={"md"}
              bg="gray.100"
              color={"gray.600"}
              placement="top-end"
              fontWeight={"normal"}
            >
              <IconButton
                size={"xs"}
                variant={note.keyNote ? "solid" : "outline"}
                colorScheme="green"
                fontSize={"18px"}
                aria-label="meka-patient-note"
                icon={<HiMiniKey />}
                onClick={() => onMakeKeyNote()}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label="Edit note"
              rounded={"md"}
              bg="gray.100"
              color={"gray.600"}
              placement="top-end"
              fontWeight={"normal"}
            >
              <IconButton
                size={"xs"}
                variant="solid"
                colorScheme="blue"
                fontSize={"18px"}
                aria-label="Edit"
                icon={<MdEditNote />}
                onClick={() => onEditSelection(note)}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label="Delete note"
              rounded={"md"}
              bg="gray.100"
              color={"gray.600"}
              placement="top-end"
              fontWeight={"normal"}
            >
              <IconButton
                onClick={() => onDeleteSelection(note)}
                size={"xs"}
                variant="solid"
                colorScheme="red"
                fontSize={"18px"}
                aria-label="Delete"
                icon={<MdOutlineDeleteSweep />}
              />
            </Tooltip>
          </Stack>
        </Flex>
      </CardHeader>
      <CardBody p="3">
        <VStack align={"self-start"} flexWrap={"grap"} w="full">
          {user && (
            <Heading
              color={"blue.500"}
              text
              size="sm"
              w="full"
              textAlign={"start"}
              whiteSpace={"pre-wrap"}
            >
              {note.title}
            </Heading>
          )}
          <Text
            w="full"
            fontSize="sm"
            textAlign={"start"}
            whiteSpace={"pre-wrap"}
            noOfLines={isCollapsed ? 5 : "none"}
          >
            {note.description}
          </Text>
          {textLength > 230 && (
            <Button
              onClick={() => {
                setIsCollapsed((prev) => !prev);
              }}
              size="xs"
              colorScheme="blue"
              variant="outline"
            >
              {isCollapsed ? "Read More" : "Read Less"}
            </Button>
          )}
          {!note.keyNote && (
            <HStack w="full">
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent={"flex-end"}
              >
                <FormLabel
                  fontSize={"xs"}
                  color={isApptNote ? "blue.500" : "gray.400"}
                  htmlFor="connect-to-appointment"
                  mb="0"
                  cursor={"pointer"}
                  mr="1"
                >
                  Specific for this appointment?
                </FormLabel>
                <Switch
                  size={"sm"}
                  isChecked={isApptNote}
                  onChange={(e) => {
                    onConnectChange(note);
                  }}
                  id="connect-to-appointment"
                />
              </FormControl>
            </HStack>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
}
function AddOrEdit({
  selectedNote,
  setSelectedNote,
  isOpen,
  setIsOpen,
  state,
  setNotes,
  type,
  dispatch,
  appointmentId,
  patientId,
}) {
  const [description, setDescription] = useState(
    selectedNote?.description || ""
  );

  const [title, setTitle] = useState(selectedNote?.title || "");
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef(null);
  const toast = useToast();
  const save = async () => {
    try {
      if (!appointmentId) return;
      setIsLoading(true);
      await saveNote({
        payload: {title, description},
        selectedNote,
        state,
        type,
        dispatch,
        appointmentId,
        patientId,
      });
      if (type === "patient")
        await fetchNotes(setNotes, patientId, appointmentId, state);
      toast({
        title: "Notes Updated",
        status: "success",
        isClosable: true,
        duration: 2000,
      });
      setIsLoading(false);
      setIsOpen(false);
      setSelectedNote(null);
    } catch (e) {
      toast({
        title: "Notes Update Failed",
        description: e.message,
        status: "error",
        isClosable: true,
        duration: 2000,
      });
    }
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedNote(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="blue.600">{`${
            selectedNote ? "Edit" : "Create"
          } Note`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form
              id="my-form"
              onSubmit={(e) => {
                e.preventDefault();
                save();
              }}
            >
              <FormControl isRequired>
                <FormLabel color="gray.600" fontSize="sm" mb="0.5">
                  Title
                </FormLabel>
                <Input
                  ref={initialRef}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel color="gray.600" fontSize="sm" mb="0.5">
                  Description
                </FormLabel>
                <Textarea
                  placeholder="write note..."
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  resize={"vertical"}
                />
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              form="my-form"
              type="submit"
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              loadingText={"Saving..."}
              disabled={isLoading}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                setSelectedNote(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

/*return (
    <div className="text-gray-700 space-y-1 mt-6">
      <Tabs index={tabIndex} onChange={handleTabsChange} isFitted>
        <TabList color={"#A1A1A1"}>
          <Tab fontSize={"0.7rem"}>Notes</Tab>
          <Tab fontSize={"0.7rem"}>Diagnostic Codes Notes</Tab>
          {appt?.cancellationReason && (
            <Tab fontSize={"0.7rem"}>Cancellation Reason</Tab>
          )}
        </TabList>
        <TabPanels>
          <TabPanel p="0" pt="2">
            <NotePanel
              value={note}
              onChange={(e) => {
                appt?.note !== e.target.value && setEdit(true);
                setNote(e.target.value);
              }}
              saveNoteData={() => saveNote({note})}
              edit={edit}
              loading={loading}
            />
          </TabPanel>
          <TabPanel p="0" pt="2">
            <NotePanel
              value={diagnosticCodesNotes}
              onChange={(e) => {
                appt?.diagnosticCodesNotes !== e.target.value && setEdit(true);
                setDiagnosticCodesNotes(e.target.value);
              }}
              saveNoteData={() => saveNote({diagnosticCodesNotes})}
              edit={edit}
              loading={loading}
            />
          </TabPanel>
          {appt?.cancellationReason && (
            <TabPanel p="0" pt="2">
              <NotePanel
                value={cancellationReason}
                onChange={(e) => {
                  appt?.cancellationReason !== e.target.value && setEdit(true);
                  setCancellationReason(e.target.value);
                }}
                saveNoteData={() => saveNote({cancellationReason})}
                edit={edit}
                loading={loading}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </div>
  );*/
