import React, {
  useState,
  useEffect,
  useRef,
} from "react";

import ServiceCriteraSetter from "./ServiceCriteriaSetter";
import ServiceBasicFields from "./ServiceBasicFields";
import {crud} from "../crudRequests";
import {v4 as uuidv4} from "uuid";
import {IoMdCheckmarkCircle} from "react-icons/io";
import {MdClose} from "react-icons/md";
import {BsInfoCircleFill} from "react-icons/bs";
import {nanoid} from "nanoid";
import {ServiceForms} from "./ServiceForms";

export default function ServiceCriteria({
  selectedService,
  setSelectedService,
  setShowEditOrCreateModal,
  allServices,
  schState,
  setServices,
  dispatch,
}) {
  const [criteria, setCriteria] = useState([]);
  const [warning, setWarning] = useState("");
  const [reset, setRestet] = useState({});
  const [showModal, setShowModal] = useState(false);
  const costsRef = useRef({});
  const [taxable, setTaxable] = useState(
    selectedService ? !!selectedService?.taxable : true
  );
  const [isfixedCost, setIsfixedCost] = useState(
    selectedService ? !!selectedService?.isfixedCost : false
  );
  const [nonSessions, setNonSessions] = useState(
    !!selectedService?.["non-sessions"]
  );
  const [assignedFormsOptions, setAssignedFormsOptions] = useState({
    firstAppointmentOnly: selectedService?.firstAppointmentOnly || false,
    assignedFormsId: selectedService?.forms || [],
  });

  const selectedDoctorsIdRef = useRef({});
  const fieldsValuesRef = useRef({});

  const [creating, setCreating] = useState(false);

  useEffect(() => {
    dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
    const abortController = new AbortController();
    let query = schState.selectedLocation
      ? {lid: schState.selectedLocation}
      : {};
    crud(
      schState,
      [
        {
          db: schState.db,
          collection: "serviceCriteria",
          parameters: [query],
          method: "find",
        },
      ],
      null,
      abortController.signal
    )
      .then((res) => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        let criteria = (res.data[0] || []).filter((c) => c.type !== "Insurers");
        setCriteria(
          criteria.concat([
            {type: "Provider Types", list: schState.providerTypes},
            {type: "Insurers", list: schState.insurers},
            {type: "Clinicians", list: schState.doctors},
          ])
        );
      })
      .catch((error) => {
        if (error.code !== "ERR_CANCELED"); // window.location.href = '../login';
      });
    return () => {
      abortController.abort();
    };
  }, [schState.selectedLocation, schState.insurers, schState.doctors]);

  async function createOrUpdateService() {
    try {
      setWarning(false);

      if (!validate()) {
        setCreating(true);
        Object.getOwnPropertyNames(fieldsValuesRef.current).forEach((e) => {
          if (typeof fieldsValuesRef.current[e] === "string")
            fieldsValuesRef.current[e] = fieldsValuesRef.current[e]?.trim();
        });
        if (fieldsValuesRef.current?.defaultCost)
          fieldsValuesRef.current.defaultCost = parseFloat(
            fieldsValuesRef.current?.defaultCost
          );
        if (fieldsValuesRef.current?.noShowFee)
          fieldsValuesRef.current.noShowFee = parseFloat(
            fieldsValuesRef.current?.noShowFee
          );
        let payload = {
          serviceId: uuidv4(),
          serviceOid: schState?.admin?.oid || schState.doctor.oid,
          attendance: "Virtual",
          lid: [schState.selectedLocation],
          costsByInsurer: {defaultCost: 100},
          ...(selectedService || {}),
          ...fieldsValuesRef.current,
          taxable,
          isfixedCost,
          "non-sessions": nonSessions,
          costsByCriteria: costsRef.current,
          forms: assignedFormsOptions.assignedFormsId,
          firstAppointmentOnly: assignedFormsOptions.firstAppointmentOnly,
        };

        let {_id, ...service} = payload;

        !selectedService
          ? await crud(
              schState,
              [
                {
                  db: schState.db,
                  collection: "services",
                  parameters: [service],
                  method: "insertOne",
                },
              ],
              null
            )
          : await crud(
              schState,
              [
                {
                  db: schState.db,
                  collection: "services",
                  parameters: [
                    {serviceId: selectedService.serviceId},
                    {$set: service},
                  ],
                  method: "updateOne",
                },
              ],
              null
            );

        /*{
              db: state.db,
              collection: "doctors",
              parameters: [{}, {$pull: {services: service.serviceId}}],
              method: "updateMany",
            },*/

        let notIncluded = schState.admin ? schState.doctors : [schState.doctor];
        notIncluded = notIncluded
          .filter((d) => !selectedDoctorsIdRef.current?.has(d.did))
          .map((e) => e.did);

        await crud(
          schState,
          [
            {
              db: schState.db,
              collection: "doctors",
              parameters: [
                {did: {$in: [...selectedDoctorsIdRef.current]}},
                {$addToSet: {services: service.serviceId}},
              ],
              method: "updateMany",
            },
            {
              db: schState.db,
              collection: "doctors",
              parameters: [
                {did: {$in: notIncluded}},
                {$pull: {services: service.serviceId}},
              ],
              method: "updateMany",
            },
          ],
          null
        );

        if (!selectedService) {
          dispatch({type: "ADD_SERVICE", payload: service});
          allServices.current = allServices.current.concat(service);
          setServices(allServices.current);
        } else {
          dispatch({type: "UPDATE_SERVICE", payload: service});
          setServices((prev) => {
            return prev.map((s) => {
              if (s.serviceId === service.serviceId) {
                Object.getOwnPropertyNames(service).forEach((e) => {
                  s[e] = service[e];
                });
              }
              return s;
            });
          });
        }

        schState.admin
          ? dispatch({
              type: "UPDATE_MULTIPLE_DOCTORS",
              payload: schState.doctors.map((d) => {
                return selectedDoctorsIdRef.current.has(d.did)
                  ? {
                      ...d,
                      services: [
                        ...new Set(
                          (d.services || []).concat(service.serviceId)
                        ),
                      ],
                    }
                  : {
                      ...d,
                      services: (d.services || []).filter(
                        (s) => s !== service.serviceId
                      ),
                    };
              }),
            })
          : dispatch({
              type: "UPDATE_DOCTOR",
              payload: {
                did: schState.selectedDoctor?.did,
                services: selectedDoctorsIdRef.current.has(
                  schState.selectedDoctor?.did
                )
                  ? [
                      ...new Set(
                        (schState.selectedDoctor?.services || []).concat(
                          service.serviceId
                        )
                      ),
                    ]
                  : (schState.selectedDoctor?.services || []).filter(
                      (s) => s !== service.serviceId
                    ),
              },
            });

        !selectedService && setRestet({});
        setCreating(false);
      } else setWarning("You must select values ​​for all basic fields!");
    } catch (error) {
      setCreating(false);
      console.log(error);
    }
  }

  function validate() {
    for (let field of Object.getOwnPropertyNames(fieldsValuesRef.current)) {
      if (field !== "defaultCost" && field !== "serviceCodes" && field !== "noShowFee") {
        if (!fieldsValuesRef.current[field]?.trim()) return true;
      }
    }

    if ([...selectedDoctorsIdRef.current].length == 0) {
      return true;
    }
    return false;
  }

  return (
    <div className="relative px-6   w-full h-full flex flex-col items-center patients-table-container overflow-y-hidden">
      {showModal && (
        <CreateCriteriaModal
          setCriteria={setCriteria}
          setShowModal={setShowModal}
          state={schState}
        />
      )}
      <div className="relative flex flex-col  items-center max-w-[1300px] max-h-[700px] h-full w-full overflow-y-scroll sbar2 p-4  bg-gradient-to-b rounded-3xl from-[#2d29ff44] to-[#7b79ff0f] bg-white ">
        {
          <MdClose
            className="text-white cursor-pointer text-lg absolute right-4 top-4"
            onClick={() => {
              setSelectedService(false);
              setShowEditOrCreateModal(false);
            }}
          />
        }
        {warning && <Warning warning={warning} setWarning={setWarning} />}
        <h1 className="font-semibold text-white text-[1.5rem]">
          Service Definition
        </h1>

        <div className="flex flex-1 p-2  overflow-hidden sbar2  items-stretch  space-x-4 w-full ">
          <ServiceBasicFields
            {...{
              taxable,
              setTaxable,
              isfixedCost,
              setIsfixedCost,
              setNonSessions,
              nonSessions,
            }}
            state={schState}
            dispatch={dispatch}
            fieldsValuesRef={fieldsValuesRef}
            creating={creating}
            reset={reset}
            selectedDoctorsIdRef={selectedDoctorsIdRef}
            selectedService={selectedService}
          />
          <ServiceCriteraSetter
            isfixedCost={isfixedCost}
            state={schState}
            dispatch={dispatch}
            criteria={criteria}
            costsRef={costsRef}
            reset={reset}
            creating={creating}
            setShowModal={setShowModal}
            selectedService={selectedService}
          />
          <ServiceForms
            selectedService={selectedService}
            schState={schState}
            setAssignedFormsOptions={setAssignedFormsOptions}
            assignedFormsOptions={assignedFormsOptions}
            setWarning={setWarning}
          />
        </div>

        <div className="rounded-2xl  mt-2  text-background bg-[#C1BFFF] mx-2">
          <button
            type="button"
            className={
              creating
                ? "w-full py-1  flex justify-center p-2 pr-3 items-center  rounded-2xl font-medium focus:outline-none border-none"
                : "w-full py-1 hover:bg-[#5754FF] flex p-2 justify-center items-center  hover:ring-0 rounded-2xl font-medium focus:outline-none border-none"
            }
            onClick={() => {
              createOrUpdateService();
            }}
            disabled={creating}
          >
            <span>
              <IoMdCheckmarkCircle />
            </span>
            {`${selectedService ? "Update" : "Create"} Service`}
            {creating && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show absolute left-10 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={() => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}

function CreateCriteriaModal({setCriteria, setShowModal, state}) {
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [fields, setFields] = useState({
    scid: nanoid(10),
    type: "",
    list: [],
    lid: [state.selectedLocation],
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newEntries = new Set([...fields.list, inputValue.trim()]);
      setInputValue("");
      setFields((prev) => {
        return {
          ...prev,
          list: [...newEntries],
        };
      });
    }
  };

  function validate() {
    return !!(fields.type && fields.list.length > 0);
  }
  async function addCriteria() {
    if (validate()) {
      setWarning("");
      setLoading(true);
      await crud(state, [
        {
          db: state.db,
          collection: "serviceCriteria",
          parameters: [{...fields, type: fields.type.trim()}],
          method: "insertOne",
        },
      ]);
      setCriteria((prev) => [fields, ...prev]);
      setShowModal(false);
    } else setWarning("Please set values ​​for required fields!");
  }

  return (
    <div
      className="fixed inset-0 show z-[60] overflow-hidden flex justify-center items-center"
      style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className="relative flex max-h-[500px] h-[370px] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[700px]"
        onClick={(e) => {
          e.stopPropagation();
          setWarning("");
        }}
      >
        {warning && (
          <Warning
            warning={warning}
            setWarning={setWarning}
            className="show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
          />
        )}
        <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
          <div className="bg-[#f6f5ff] mx-2 text-left  p-5  rounded-xl overflow-hidden relative">
            {/* close button */}
            <button
              type="button"
              className="- text-gray-400  bg-transparent  absolute top-5 right-5 rounded-lg text-sm   inline-flex items-center cursor-pointer focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <h3
              className="text-2xl font-medium px-5 mb-3 text-gray-800"
              style={{color: "rgb(192, 191, 255)"}}
            >
              {"Create Criteria"}
            </h3>
            <div className="h-full mt-3">
              <div className="overflow-hidden grid grid-cols-2 gap-3 pb-5  rounded-md">
                <label className="block px-2 my-1" htmlFor="type">
                  <span className="text-[13px] ml-2  text-off ">
                    Criteria Name*
                  </span>
                  <input
                    type="text"
                    name="type"
                    id="type"
                    value={fields.type}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          type: e.target.value,
                        };
                      });
                    }}
                    className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  />
                </label>
                <label
                  className="relative block px-2 my-1 group"
                  htmlFor="values"
                >
                  <span className="text-[13px] ml-2  text-off ">Options*</span>
                  <input
                    name="values"
                    id="values"
                    className=" block w-full peer  py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    value={inputValue}
                  />
                  <span className="hidden absolute -bottom-[17px] right-2  text-[10px] mt-1 text-yellow-500 peer-focus:flex justify-end items-center space-x-1">
                    <span className="">
                      {"Type an option and press enter."}
                    </span>
                    <BsInfoCircleFill />
                  </span>
                </label>
              </div>
              <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
                <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[80px]  w-full sbar2   p-2">
                  {fields.list.map((entry, index) => (
                    <li
                      key={index}
                      className="inline-block mx-1 my-1  py-1 text-sm text-white font-medium bg-[#ACAAFF] rounded-md cursor-pointer relative px-4"
                      onClick={() => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            list: prev.list.filter((f) => f !== entry),
                          };
                        });
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-2 h-2 absolute to left-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <p className="">{entry}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="mt-6 w-4/5 mb-4 mx-auto">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addCriteria();
                }}
                disabled={loading}
                className=" w-full rounded-lg flex justify-center items-center text-background bg-med py-2"
              >
                {"Create criteria"}
                {loading && (
                  <span className="">
                    <svg
                      className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25 stroke-[4px]"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
