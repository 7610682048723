import {Box, FormControl, FormLabel, Switch} from "@chakra-ui/react";

export const AssignedServiceFormSwitch = ({
  firstAppointmentOnly,
  setAssignedFormsOptions,
}) => {
  return (
    <Box h="10%">
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="first-appointment-only" mb="0" color="gray.500" fontSize="1rem">
          Send on first appointment only
        </FormLabel>
        <Switch
          id="first-appointment-only"
          isChecked={firstAppointmentOnly}
          onChange={() => setAssignedFormsOptions((prev) => ({
            ...prev,
            firstAppointmentOnly: !prev.firstAppointmentOnly
          }))}
          size="sm"
          colorScheme="purple"
        />
      </FormControl>
    </Box>
  );
};
